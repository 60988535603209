* {
  font-family: 'Lora', sans-serif;
}

@import url('https://fonts.cdnfonts.com/css/pixel-craft');
@import url('https://fonts.cdnfonts.com/css/mrs-monster-3d');
@import url('https://fonts.googleapis.com/css2?family=Henny+Penny&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500&display=swap');

.openseaButton {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  background-color: #58d4ff;
  color: #ffffff;
  border: none;
  font-size: 100px;
  padding: 10px;
  transition: 0.5s ease;
  padding-left: 15px;
  padding-right: 15px;
  background-image: linear-gradient(to right,
      #ff7d84,
      #ff7d84 16.65%,
      #ff733f 16.65%,
      #ff733f 33.3%,
      #39b6ec 33.3%,
      #39b6ec 49.95%,
      #ecd435 49.95%,
      #ecd435 66.6%,
      #bc3ceb 66.6%,
      #bc3ceb 83.25%,
      #fe8abe 83.25%,
      #fe8abe 100%,
      #E7484F 100%);
  animation: slidebg 2s linear infinite;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  cursor: none;
}

.ticker-container {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  height: 50px;
  background: black;
  color: white;
  font-size: 24px;
  font-family: Arial, sans-serif;
}

.ticker-text {
  display: inline-block;
  padding-right: 100%;
  animation: moveTicker 10s linear infinite;
}

@keyframes moveTicker {
  100% {
    transform: translateX(-100%);
  }
}

.tImg {
  width: 30px;
}

.tokenLink2:hover {
  color: #4d4d4d;
}

.buyImg:hover {
  cursor: pointer;
}

@media screen and (max-width: 499px) {


  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 10%;
    padding-right: 5%;
    background-color: #1a1a1a5d;
    display: flex;
    justify-content: flex-end;

    margin-left: auto;
    margin-right: auto;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }


  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar2 {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 5px;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #9bc6ff solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 50px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }


  @keyframes neonGlow {
    0% {
      border-color: #62b9e7;
      box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    }

    50% {
      border-color: #e82598;
      box-shadow: 0 0 10px #62b9e7, 0 0 25px #62b9e7, 0 0 35px #62b9e7;
    }

    100% {
      border-color: #62b9e7;
      box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    }
  }

  .btnfos-0-3-3 .initialText {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  }

  .btnfos-0-3-3:hover .initialText {
    opacity: 0;
    visibility: hidden;
  }

  .btnfos-0-3-3 .hoverText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    width: 90%;
  }

  .btnfos-0-3-3:hover .hoverText {
    opacity: 1;
    visibility: visible;
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .logoDiv {
    cursor: pointer;
  }


  ::-webkit-scrollbar {
    width: 15px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #96df48;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 171, 171);
  }

  .buyImg {
    width: 85px;
    margin-left: 3%;
    margin-top: 10px;
  }

  .boxWrap2Page {
    background-color: #010408;
    overflow: hidden;
  }

  .usdAmount img {
    width: 30px;
    height: 30px;
  }

  .conT4 {
    color: white;
    text-align: center;
    font-size: 30px;
    font-family: 'Doctor Glitch', sans-serif;
    margin-bottom: 2%;
    text-shadow: 0 0 10px #ffffff8a;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 81px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    opacity: 0.85;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }


  .usdAmount {
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 10px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1d2129;
    padding: 10px;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 15px #e82598, 0 0 25px #e82598;
    animation: neonGlow 2s infinite;
    /* Adjust the duration as needed */
    margin-top: 50px;
    margin-bottom: 80px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: linear-gradient(309deg, rgba(33, 43, 54, 1) 0%, rgba(20, 25, 31, 1) 50%, rgba(59, 42, 69, 1) 100%);
  }

  .usdT {
    font-family: 'Doctor Glitch', sans-serif;
    font-size: 22px;
  }

  .usd2 {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 90%;
  }

  .logo2Mobile {
    display: none;
  }

  .logoDivMob {
    display: none;
  }

  .token {
    width: 70%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;

  }

  .d1 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
  }

  .square {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  .glitchLoad {
    font-size: 30px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Doctor Glitch', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }


  .tokenomicsCon {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff934;
    padding: 20px;
    width: 90%;
    color: black;
    box-shadow: 0 5px rgb(245, 25, 54);
  }

  .tokenLink {

    font-size: 20px;
    font-weight: 500;
  }

  .tokenLink2 {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  .about3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 7%;

  }

  .videoMain {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .aboutToken {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 85%;
    background-color: #00000077;
    padding: 20px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 50px;
    border: 4px dashed;
    margin-bottom: 70px;
    animation: colorChange 5s infinite alternate;
  }

  @keyframes colorChange {
    0% {
      border-color: #93e001;
    }

    25% {
      border-color: #e0c401;
    }

    50% {
      border-color: #7418eb;
    }

    75% {
      border-color: #ff5a27;
    }

    100% {
      border-color: #f78ec3;
    }
  }


  .punkSubH {
    color: rgb(192, 251, 255);
    margin-bottom: 50px;
    width: 90%;
    margin-left: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #0000003a;
    border-radius: 20px;
    padding: 10px;
    margin-top: 3%;
    font-family: 'Caveat', sans-serif;
    font-size: 25px;
    border: 4px dashed #c579ff;
  }

  .stickyRm {
    position: sticky;
    bottom: 0;
    margin-top: -10%;
    width: 40%;
    margin-left: 60%;
  }

  .bg {
    background-position: center;
    width: 100%;
    height: min-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;

    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: 'PIXEL CRAFT', sans-serif;
    letter-spacing: 2px;
    -webkit-text-stroke: 1px black;
  }

  #fontSize:hover {
    color: #d03fe6;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
  }

  /* CSS */
  .button-71 {
    background-color: #d03fe6;
    border: 0;
    border-radius: 56px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: 'PIXEL CRAFT', sans-serif;
    font-size: 16px;
    font-weight: 600;
    outline: 0;
    padding: 12px 15px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 10px;
    letter-spacing: 2px;

  }

  .button-71:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 92%;
  }

  .button-71:hover {
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    .button-71 {
      padding: 16px 48px;
    }
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-top: 10px;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 3px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-right: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: url('./assets/r1.jpg') center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 15vh;

  }

  .dinos {
    display: flex;
    justify-content: center;
  }

  .dinos img {
    width: 100%;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    margin-right: auto;
  }

  .logoDiv img {
    width: 70px;
    border-radius: 100%;
    border: 2px solid rgb(255, 255, 255);
  }

  .title {
    z-index: 1;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18vh;
    font-family: 'PIXEL CRAFT', sans-serif;
    font-size: 27px;
    background: -webkit-linear-gradient(#ffd900, #ed614e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    letter-spacing: 3px;
  }

  .subTitle {
    font-family: 'Mrs. Monster 3D', sans-serif;
    font-size: 18px;
    text-align: center;
    color: #413f3f;
    font-weight: bold;
    margin-top: 10px;
  }


  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }

  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  .boxWrap2StoryB {
    padding-top: 10%;
    padding-bottom: 10%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Story3 {
    background: url('./assets/pxArt2\ \(2\).jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    padding-top: 10%;
    border-bottom: 5px solid rgb(253, 255, 140);
  }

  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-bottom: 5%;
    background: url('./assets/r3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .mintUp {
    margin-top: 5%;
  }

  .boxWrap2Footer {
    padding-top: 20vh;
    padding-bottom: 20vh;
    background: url('./assets/pxArt\ \(1\).jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    background: url('./assets/pxArt3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }

  .aboutA {
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
    margin-left: auto;
    width: 95%;
    padding: 50px;

  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .about img {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .about video {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 35px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 10%;
    background: -webkit-linear-gradient(#b84ff5, #6c09a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  }

  .conT-2 {
    font-size: 35px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 10%;
    background: -webkit-linear-gradient(#69fcb5, #03a05e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  }

  .conT2 {
    font-size: 23px;
    color: #dadd55;
    z-index: 1;
    text-align: center;
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
  }

  .hl {
    color: #89e251;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Caveat', sans-serif;
    font-size: 20px;
  }

  .conTNew {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Doctor Glitch', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Doctor Glitch', sans-serif;

  }

  .conTMob {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Doctor Glitch', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Doctor Glitch', sans-serif;
  }

  .storyCon {
    font-size: 18px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: 'Lora', sans-serif;
  }

  .aboutToken p {
    color: white;
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Lora', sans-serif;
    color: white;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px #81f5fd dashed;
    padding: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-color: #00000038;
    flex-flow: column nowrap;
  }

  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 100%;
    border-radius: 20px;
    border: 3px solid rgb(255, 255, 255);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .totalSupply {
    text-align: center;
    font-size: 28px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;

    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 16px;
    color: rgb(255, 255, 255);

    font-family: 'Lora', sans-serif;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 40px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 20px;
    margin-left: 30px;
    margin-right: 30px;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    background-image: linear-gradient(#0dccea, #0d70ea);
    border: 0;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
    border-radius: 5px;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3-3 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 22px;
    width: 90%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: no-drop;
    border-radius: 5px;
    border: 2px solid white;
  }

  .btnfos-0-3-3:hover {
    background-color: rgb(97, 142, 179);
  }

  .btnfos-0-3-3:active {
    background-color: rgb(97, 142, 179);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .btnfos-0-3 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 22px;
    width: 90%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    border-radius: 5px;
    border: 2px solid white;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 65px;
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    background: -webkit-linear-gradient(#b84ff5, #6c09a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #26ff00;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .name {
    font-family: 'Doctor Glitch', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 50px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 18px;
    color: whitesmoke;
  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .icons img {
    width: 40px;
    height: 40px;
    margin-left: 15px;
    margin-right: 15px;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 16px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .footerT {
    font-family: 'Doctor Glitch', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;

  }

  .glassy {
    background-color: #000000b0;
    padding: 30px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 50px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 320px;
    list-style-type: none;
    margin-left: -50px;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 55px 158px;

  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -95px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px #c588ff;
    font-family: 'Henny Penny';
    background: -webkit-linear-gradient(#ffe89c, #f54fbe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .screen {
    display: none;
  }

  .smallL {
    font-size: 16px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .special {
    font-weight: 500;
    text-shadow: 0 0 10px #bef86c;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -95px;
    text-shadow: 0 0 10px #e3ffbc;
    font-family: 'Lora', sans-serif;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 45px;
    height: 45px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 7px;
    background-color: #9fdf43;
    left: 26px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 7px;
    background-color: #c5c3cb8a;
    left: 26px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #88c647;
    border: 5px dashed #365a04;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #6aa728;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 30px;
    height: 30px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyDiv {
    display: flex;
    justify-content: space-between;
    bottom: 0;
    position: sticky;
    margin-top: -5%;
  }

  .stickyImg {
    width: 30%;
    height: 20%;
    bottom: 0;
    z-index: 1;
    position: sticky;
    margin-top: -10%;
  }

  .sticky {
    width: 20%;
    height: 20%;
    bottom: 0;
    z-index: 1;
  }

  .sticky2 {
    width: 20%;
    bottom: 0;
    z-index: 1;
  }

  .div {
    width: 100%;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 5.5px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #c723a3);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgba(107, 215, 254, 0.425);
    overflow: hidden;
    border-right: rgba(95, 158, 160, 0);

  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 100s;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    background: -webkit-linear-gradient(#ffffff, #f5c5dd, #82e480);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
    font-family: 'Henny Penny';
  }

}


@media screen and (min-width: 500px) and (max-width: 767px) {

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 10%;
    padding-right: 5%;
    background-color: #1a1a1a5d;
    display: flex;
    justify-content: flex-end;

    margin-left: auto;
    margin-right: auto;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }


  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar2 {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 5px;

  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #9bc6ff solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 50px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }


  @keyframes neonGlow {
    0% {
      border-color: #62b9e7;
      box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    }

    50% {
      border-color: #e82598;
      box-shadow: 0 0 10px #62b9e7, 0 0 25px #62b9e7, 0 0 35px #62b9e7;
    }

    100% {
      border-color: #62b9e7;
      box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    }
  }

  .btnfos-0-3-3 .initialText {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  }

  .btnfos-0-3-3:hover .initialText {
    opacity: 0;
    visibility: hidden;
  }

  .btnfos-0-3-3 .hoverText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    width: 90%;
  }

  .btnfos-0-3-3:hover .hoverText {
    opacity: 1;
    visibility: visible;
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .logoDiv {
    cursor: pointer;
  }


  ::-webkit-scrollbar {
    width: 15px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #96df48;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 171, 171);
  }

  .buyImg {
    width: 85px;
    margin-left: 3%;
    margin-top: 10px;
  }

  .boxWrap2Page {
    background-color: #010408;
    overflow: hidden;
  }

  .usdAmount img {
    width: 30px;
    height: 30px;
  }

  .conT4 {
    color: white;
    text-align: center;
    font-size: 30px;
    font-family: 'Doctor Glitch', sans-serif;
    margin-bottom: 2%;
    text-shadow: 0 0 10px #ffffff8a;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 81px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    opacity: 0.85;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }


  .usdAmount {
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 10px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1d2129;
    padding: 10px;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 15px #e82598, 0 0 25px #e82598;
    animation: neonGlow 2s infinite;
    /* Adjust the duration as needed */
    margin-top: 50px;
    margin-bottom: 80px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: linear-gradient(309deg, rgba(33, 43, 54, 1) 0%, rgba(20, 25, 31, 1) 50%, rgba(59, 42, 69, 1) 100%);
  }

  .usdT {
    font-family: 'Doctor Glitch', sans-serif;
    font-size: 22px;
  }

  .usd2 {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 90%;
  }

  .logo2Mobile {
    display: none;
  }

  .logoDivMob {
    display: none;
  }

  .token {
    width: 70%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;

  }

  .d1 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
  }

  .square {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  .glitchLoad {
    font-size: 30px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Doctor Glitch', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }


  .tokenomicsCon {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff934;
    padding: 20px;
    width: 90%;
    color: black;
    box-shadow: 0 5px rgb(245, 25, 54);
  }

  .tokenLink {

    font-size: 20px;
    font-weight: 500;
  }

  .tokenLink2 {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  .about3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 7%;

  }

  .videoMain {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .aboutToken {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 85%;
    background-color: #00000077;
    padding: 30px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 50px;
    border: 4px dashed;
    margin-bottom: 70px;
    animation: colorChange 5s infinite alternate;
  }

  @keyframes colorChange {
    0% {
      border-color: #93e001;
    }

    25% {
      border-color: #e0c401;
    }

    50% {
      border-color: #7418eb;
    }

    75% {
      border-color: #ff5a27;
    }

    100% {
      border-color: #f78ec3;
    }
  }


  .punkSubH {
    color: rgb(192, 251, 255);
    margin-bottom: 50px;
    width: 80%;
    margin-left: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #0000003a;
    border-radius: 20px;
    padding: 10px;
    margin-top: 3%;
    font-family: 'Caveat', sans-serif;
    font-size: 25px;
    border: 4px dashed #c579ff;
  }

  .stickyRm {
    position: sticky;
    bottom: 0;
    margin-top: -10%;
    width: 40%;
    margin-left: 60%;
  }

  .bg {
    background-position: center;
    width: 100%;
    height: min-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;

    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: 'PIXEL CRAFT', sans-serif;
    letter-spacing: 2px;
    -webkit-text-stroke: 1px black;
  }

  #fontSize:hover {
    color: #d03fe6;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
  }

  /* CSS */
  .button-71 {
    background-color: #d03fe6;
    border: 0;
    border-radius: 56px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: 'PIXEL CRAFT', sans-serif;
    font-size: 16px;
    font-weight: 600;
    outline: 0;
    padding: 12px 15px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 10px;
    letter-spacing: 2px;

  }

  .button-71:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 92%;
  }

  .button-71:hover {
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    .button-71 {
      padding: 16px 48px;
    }
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-top: 10px;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 3px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-right: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: url('./assets/r1.jpg') center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }



  .introduction {
    margin-bottom: 15vh;

  }

  .dinos {
    display: flex;
    justify-content: center;
  }

  .dinos img {
    width: 100%;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    margin-right: auto;
  }

  .logoDiv img {
    width: 70px;
    border-radius: 100%;
    border: 2px solid rgb(255, 255, 255);
  }

  .title {
    z-index: 1;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18vh;
    font-family: 'PIXEL CRAFT', sans-serif;
    font-size: 45px;
    background: -webkit-linear-gradient(#ffd900, #ed614e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: black;
    letter-spacing: 3px;
  }

  .subTitle {
    font-family: 'Mrs. Monster 3D', sans-serif;
    font-size: 20px;
    text-align: center;
    color: #413f3f;
    font-weight: bold;
  }


  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }

  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }



  .boxWrap2StoryB {
    padding-top: 10%;
    padding-bottom: 10%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Story3 {
    background: url('./assets/pxArt2\ \(2\).jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    padding-top: 10%;
    border-bottom: 5px solid rgb(253, 255, 140);
  }



  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }



  .boxWrap2Mint {
    padding-bottom: 5%;
    background: url('./assets/r3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .mintUp {
    margin-top: 5%;
  }


  .boxWrap2Footer {
    padding-top: 20vh;
    padding-bottom: 20vh;
    background: url('./assets/pxArt\ \(1\).jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    background: url('./assets/pxArt3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }

  .aboutA {
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
    margin-left: auto;
    width: 95%;
    padding: 50px;

  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .about img {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .about video {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 40px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 10%;
    background: -webkit-linear-gradient(#b84ff5, #6c09a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .conT-2 {
    font-size: 40px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 10%;
    background: -webkit-linear-gradient(#69fcb5, #03a05e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .conT2 {
    font-size: 25px;
    color: #dadd55;
    z-index: 1;
    text-align: center;
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
  }

  .hl {
    color: #89e251;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Caveat', sans-serif;
    font-size: 20px;
  }

  .conTNew {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Doctor Glitch', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Doctor Glitch', sans-serif;

  }

  .conTMob {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Doctor Glitch', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Doctor Glitch', sans-serif;
  }

  .storyCon {
    font-size: 18px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: 'Lora', sans-serif;
  }

  .aboutToken p {
    color: white;
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Lora', sans-serif;
    color: white;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px #81f5fd dashed;
    padding: 50px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-color: #00000038;
    flex-flow: column nowrap;
  }

  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 300px;
    height: 300px;
    border-radius: 20px;
    border: 3px solid rgb(255, 255, 255);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;

    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: rgb(255, 255, 255);

    font-family: 'Lora', sans-serif;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 40px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 20px;
    margin-left: 30px;
    margin-right: 30px;
    width: 60px;
    height: 60px;
    border: none;
    cursor: pointer;
    background-image: linear-gradient(#0dccea, #0d70ea);
    border: 0;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
    border-radius: 5px;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3-3 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 22px;
    width: 90%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: no-drop;
    border-radius: 5px;
    border: 2px solid white;
  }

  .btnfos-0-3-3:hover {
    background-color: rgb(97, 142, 179);
  }

  .btnfos-0-3-3:active {
    background-color: rgb(97, 142, 179);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .btnfos-0-3 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 22px;
    width: 90%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    border-radius: 5px;
    border: 2px solid white;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 80px;
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    background: -webkit-linear-gradient(#b84ff5, #6c09a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #26ff00;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 20px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 16px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .name {
    font-family: 'Doctor Glitch', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 50px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 18px;
    color: whitesmoke;
  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .icons img {
    width: 45px;
    height: 45px;
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 130px;
    height: 130px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 16px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .footerT {
    font-family: 'Doctor Glitch', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;

  }

  .glassy {
    background-color: #000000b0;
    padding: 30px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 50px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 450px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 55px 158px;

  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -80px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px #c588ff;
    font-family: 'Henny Penny';
    background: -webkit-linear-gradient(#ffe89c, #f54fbe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .mobH2 {
    display: none;
  }

  .smallL {
    font-size: 18px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .special {
    font-weight: 500;
    text-shadow: 0 0 10px #bef86c;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -80px;
    text-shadow: 0 0 10px #e3ffbc;
    font-family: 'Lora', sans-serif;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 60px;
    height: 60px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #9fdf43;
    left: 32px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 32px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #88c647;
    border: 5px dashed #365a04;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #6aa728;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyDiv {
    display: flex;
    justify-content: space-between;
    bottom: 0;
    position: sticky;
    margin-top: -5%;
  }

  .stickyImg {
    width: 30%;
    height: 20%;
    bottom: 0;
    z-index: 1;
    position: sticky;
    margin-top: -10%;
  }

  .sticky {
    width: 20%;
    height: 20%;
    bottom: 0;
    z-index: 1;
  }

  .sticky2 {
    width: 20%;
    bottom: 0;
    z-index: 1;
  }

  .div {
    width: 100%;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 5.5px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #c723a3);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgba(107, 215, 254, 0.425);
    overflow: hidden;
    border-right: rgba(95, 158, 160, 0);

  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 100s;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    background: -webkit-linear-gradient(#ffffff, #f5c5dd, #82e480);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
    font-family: 'Henny Penny';
  }

}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 10%;
    padding-right: 5%;
    background-color: #1a1a1a5d;
    display: flex;
    justify-content: flex-end;

    margin-left: auto;
    margin-right: auto;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }


  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar2 {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 5px;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #9bc6ff solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 50px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }


  @keyframes neonGlow {
    0% {
      border-color: #62b9e7;
      box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    }

    50% {
      border-color: #e82598;
      box-shadow: 0 0 10px #62b9e7, 0 0 25px #62b9e7, 0 0 35px #62b9e7;
    }

    100% {
      border-color: #62b9e7;
      box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    }
  }

  .btnfos-0-3-3 .initialText {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  }

  .btnfos-0-3-3:hover .initialText {
    opacity: 0;
    visibility: hidden;
  }

  .btnfos-0-3-3 .hoverText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    width: 90%;
  }

  .btnfos-0-3-3:hover .hoverText {
    opacity: 1;
    visibility: visible;
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .logoDiv {
    cursor: pointer;
  }


  ::-webkit-scrollbar {
    width: 15px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #96df48;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 171, 171);
  }

  .buyImg {
    width: 85px;
    margin-left: 3%;
    margin-top: 10px;
  }

  .boxWrap2Page {
    background-color: #010408;
    overflow: hidden;
  }

  .usdAmount img {
    width: 30px;
    height: 30px;
  }

  .conT4 {
    color: white;
    text-align: center;
    font-size: 30px;
    font-family: 'Doctor Glitch', sans-serif;
    margin-bottom: 2%;
    text-shadow: 0 0 10px #ffffff8a;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 81px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    opacity: 0.85;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }


  .usdAmount {
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 10px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1d2129;
    padding: 10px;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 15px #e82598, 0 0 25px #e82598;
    animation: neonGlow 2s infinite;
    /* Adjust the duration as needed */
    margin-top: 50px;
    margin-bottom: 80px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: linear-gradient(309deg, rgba(33, 43, 54, 1) 0%, rgba(20, 25, 31, 1) 50%, rgba(59, 42, 69, 1) 100%);
  }

  .usdT {
    font-family: 'Doctor Glitch', sans-serif;
    font-size: 22px;
  }

  .usd2 {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 90%;
  }

  .logo2Mobile {
    display: none;
  }

  .logoDivMob {
    display: none;
  }

  .token {
    width: 40%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;

  }

  .d1 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
  }

  .square {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  .glitchLoad {
    font-size: 30px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Doctor Glitch', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }


  .tokenomicsCon {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff934;
    padding: 20px;
    width: 90%;
    color: black;
    box-shadow: 0 5px rgb(245, 25, 54);
  }

  .tokenLink {

    font-size: 20px;
    font-weight: 500;
  }

  .tokenLink2 {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  .about3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 7%;

  }

  .videoMain {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .aboutToken {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 80%;
    background-color: #00000077;
    padding: 30px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 50px;
    border: 5px dashed;
    margin-bottom: 70px;
    animation: colorChange 5s infinite alternate;
  }

  @keyframes colorChange {
    0% {
      border-color: #93e001;
    }

    25% {
      border-color: #e0c401;
    }

    50% {
      border-color: #7418eb;
    }

    75% {
      border-color: #ff5a27;
    }

    100% {
      border-color: #f78ec3;
    }
  }


  .punkSubH {
    color: rgb(192, 251, 255);
    margin-bottom: 50px;
    width: 80%;
    margin-left: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #0000003a;
    border-radius: 20px;
    padding: 10px;
    margin-top: 3%;
    font-family: 'Caveat', sans-serif;
    font-size: 25px;
    border: 4px dashed #c579ff;
  }

  .stickyRm {
    position: sticky;
    bottom: 0;
    margin-top: -10%;
    width: 40%;
    margin-left: 60%;
  }

  .bg {
    background-position: center;
    width: 100%;
    height: min-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;

    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: 'PIXEL CRAFT', sans-serif;
    letter-spacing: 2px;
    -webkit-text-stroke: 1px black;
  }

  #fontSize:hover {
    color: #d03fe6;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-right: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
  }

  /* CSS */
  .button-71 {
    background-color: #d03fe6;
    border: 0;
    border-radius: 56px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: 'PIXEL CRAFT', sans-serif;
    font-size: 16px;
    font-weight: 600;
    outline: 0;
    padding: 12px 15px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 10px;
    letter-spacing: 2px;

  }

  .button-71:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 92%;
  }

  .button-71:hover {
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    .button-71 {
      padding: 16px 48px;
    }
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-top: 10px;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 3px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-right: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: url('./assets/r1.jpg') center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }



  .introduction {
    margin-bottom: 15vh;

  }

  .dinos {
    display: flex;
    justify-content: center;
  }

  .dinos img {
    width: 100%;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    margin-right: auto;
  }

  .logoDiv img {
    width: 70px;
    border-radius: 100%;
    border: 2px solid rgb(255, 255, 255);
  }

  .title {
    z-index: 1;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18vh;
    font-family: 'PIXEL CRAFT', sans-serif;
    font-size: 70px;
    background: -webkit-linear-gradient(#ffd900, #ed614e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .subTitle {
    font-family: 'Mrs. Monster 3D', sans-serif;
    font-size: 24px;
    text-align: center;
    color: #413f3f;
    font-weight: bold;
  }


  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }

  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }



  .boxWrap2StoryB {
    padding-top: 10%;
    padding-bottom: 10%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Story3 {
    background: url('./assets/pxArt2\ \(2\).jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    padding-top: 10%;
    border-bottom: 5px solid rgb(253, 255, 140);
  }



  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }



  .boxWrap2Mint {
    padding-bottom: 5%;
    background: url('./assets/r3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .mintUp {
    margin-top: 5%;
  }


  .boxWrap2Footer {
    padding-top: 20vh;
    padding-bottom: 20vh;
    background: url('./assets/pxArt\ \(1\).jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    background: url('./assets/pxArt3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }

  .aboutA {
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
    margin-left: auto;
    width: 95%;
    padding: 50px;

  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .about img {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .about video {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 40px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 10%;
    background: -webkit-linear-gradient(#b84ff5, #6c09a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .conT-2 {
    font-size: 40px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 10%;
    background: -webkit-linear-gradient(#69fcb5, #03a05e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .conT2 {
    font-size: 30px;
    color: #dadd55;
    z-index: 1;
    text-align: center;
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
  }

  .hl {
    color: #89e251;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Caveat', sans-serif;
    font-size: 22px;
  }

  .conTNew {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Doctor Glitch', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Doctor Glitch', sans-serif;

  }

  .conTMob {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Doctor Glitch', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Doctor Glitch', sans-serif;
  }

  .storyCon {
    font-size: 18px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: 'Lora', sans-serif;
  }

  .aboutToken p {
    color: white;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Lora', sans-serif;
    color: white;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px #81f5fd dashed;
    padding: 50px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-color: #00000038;
    flex-flow: column nowrap;
  }

  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 400px;
    height: 400px;
    border-radius: 20px;
    border: 3px solid rgb(255, 255, 255);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;

    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);

    font-family: 'Lora', sans-serif;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 40px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: 70px;
    height: 70px;
    border: none;
    cursor: pointer;
    background-image: linear-gradient(#0dccea, #0d70ea);
    border: 0;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
    border-radius: 5px;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3-3 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 90%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: no-drop;
    border-radius: 5px;
    border: 2px solid white;
  }

  .btnfos-0-3-3:hover {
    background-color: rgb(97, 142, 179);
  }

  .btnfos-0-3-3:active {
    background-color: rgb(97, 142, 179);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .btnfos-0-3 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 90%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: pointer;
    border-radius: 5px;
    border: 2px solid white;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 90px;
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    background: -webkit-linear-gradient(#b84ff5, #6c09a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #26ff00;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .name {
    font-family: 'Doctor Glitch', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 50px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 18px;
    color: whitesmoke;
  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .icons img {
    width: 45px;
    height: 45px;
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 130px;
    height: 130px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 16px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .footerT {
    font-family: 'Doctor Glitch', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;

  }

  .glassy {
    background-color: #000000b0;
    padding: 30px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 50px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 650px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;

  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px #c588ff;
    font-family: 'Henny Penny';
    background: -webkit-linear-gradient(#ffe89c, #f54fbe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .mobH2 {
    display: none;
  }

  .smallL {
    font-size: 18px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .special {
    font-weight: 500;
    text-shadow: 0 0 10px #bef86c;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #e3ffbc;
    font-family: 'Lora', sans-serif;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #9fdf43;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #88c647;
    border: 5px dashed #365a04;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #6aa728;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyDiv {
    display: flex;
    justify-content: space-between;
    bottom: 0;
    position: sticky;
    margin-top: -5%;
  }

  .stickyImg {
    width: 20%;
    height: 20%;
    bottom: 0;
    z-index: 1;
    position: sticky;
    margin-top: -10%;
  }

  .sticky {
    width: 20%;
    height: 20%;
    bottom: 0;
    z-index: 1;
  }

  .sticky2 {
    width: 20%;
    bottom: 0;
    z-index: 1;
  }

  .div {
    width: 100%;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 5.5px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #c723a3);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgba(107, 215, 254, 0.425);
    overflow: hidden;
    border-right: rgba(95, 158, 160, 0);

  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 100s;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    background: -webkit-linear-gradient(#ffffff, #f5c5dd, #82e480);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
    font-family: 'Henny Penny';
  }

}

@media screen and (min-width: 992px) and (max-width: 1199px) {


  @keyframes neonGlow {
    0% {
      border-color: #62b9e7;
      box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    }

    50% {
      border-color: #e82598;
      box-shadow: 0 0 10px #62b9e7, 0 0 25px #62b9e7, 0 0 35px #62b9e7;
    }

    100% {
      border-color: #62b9e7;
      box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    }
  }

  .btnfos-0-3-3 .initialText {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  }

  .btnfos-0-3-3:hover .initialText {
    opacity: 0;
    visibility: hidden;
  }

  .btnfos-0-3-3 .hoverText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    width: 90%;
  }

  .btnfos-0-3-3:hover .hoverText {
    opacity: 1;
    visibility: visible;
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .logoDiv {
    cursor: pointer;
  }


  ::-webkit-scrollbar {
    width: 15px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #96df48;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 171, 171);
  }

  .buyImg {
    width: 85px;
    margin-left: 3%;
    margin-top: 10px;
  }

  .boxWrap2Page {
    background-color: #010408;
    overflow: hidden;
  }

  .usdAmount img {
    width: 30px;
    height: 30px;
  }

  .conT4 {
    color: white;
    text-align: center;
    font-size: 30px;
    font-family: 'Doctor Glitch', sans-serif;
    margin-bottom: 2%;
    text-shadow: 0 0 10px #ffffff8a;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 81px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    opacity: 0.85;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }


  .usdAmount {
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 10px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1d2129;
    padding: 10px;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 15px #e82598, 0 0 25px #e82598;
    animation: neonGlow 2s infinite;
    /* Adjust the duration as needed */
    margin-top: 50px;
    margin-bottom: 80px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: linear-gradient(309deg, rgba(33, 43, 54, 1) 0%, rgba(20, 25, 31, 1) 50%, rgba(59, 42, 69, 1) 100%);
  }

  .usdT {
    font-family: 'Doctor Glitch', sans-serif;
    font-size: 22px;
  }

  .usd2 {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 90%;
  }

  .logo2Mobile {
    display: none;
  }

  .logoDivMob {
    display: none;
  }

  .token {
    width: 40%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;

  }

  .d1 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
  }

  .square {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  .glitchLoad {
    font-size: 30px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Doctor Glitch', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }


  .tokenomicsCon {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff934;
    padding: 20px;
    width: 90%;
    color: black;
    box-shadow: 0 5px rgb(245, 25, 54);
  }

  .tokenLink {

    font-size: 20px;
    font-weight: 500;
  }

  .tokenLink2 {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  .about3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 7%;

  }

  .videoMain {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .aboutToken {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 55%;
    background-color: #00000077;
    padding: 30px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 50px;
    border: 5px dashed;
    margin-bottom: 70px;
    animation: colorChange 5s infinite alternate;
  }

  @keyframes colorChange {
    0% {
      border-color: #93e001;
    }

    25% {
      border-color: #e0c401;
    }

    50% {
      border-color: #7418eb;
    }

    75% {
      border-color: #ff5a27;
    }

    100% {
      border-color: #f78ec3;
    }
  }


  .punkSubH {
    color: rgb(192, 251, 255);
    margin-bottom: 50px;
    width: 50%;
    margin-left: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #0000003a;
    border-radius: 20px;
    padding: 10px;
    margin-top: 3%;
    font-family: 'Caveat', sans-serif;
    font-size: 30px;
    border: 4px dashed #c579ff;
  }

  .stickyRm {
    position: sticky;
    bottom: 0;
    margin-top: -10%;
    width: 40%;
    margin-left: 60%;
  }

  .bg {
    background-position: center;
    width: 100%;
    height: min-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 18px;
    cursor: pointer;

    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: 'PIXEL CRAFT', sans-serif;
    letter-spacing: 2px;
    -webkit-text-stroke: 1px black;
  }

  #fontSize:hover {
    color: #d03fe6;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }


  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  /* CSS */
  .button-71 {
    background-color: #d03fe6;
    border: 0;
    border-radius: 56px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: 'PIXEL CRAFT', sans-serif;
    font-size: 18px;
    font-weight: 600;
    outline: 0;
    padding: 16px 21px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 10px;
    letter-spacing: 2px;

  }

  .button-71:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 92%;
  }

  .button-71:hover {
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    .button-71 {
      padding: 16px 48px;
    }
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-top: 10px;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 3px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-right: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: url('./assets/r1.jpg') center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }



  .introduction {
    margin-bottom: 15vh;

  }

  .dinos {
    display: flex;
    justify-content: center;
  }

  .dinos img {
    width: 100%;
    margin-top: -5vh;
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
  }

  .logoDiv img {
    width: 80px;
    border-radius: 100%;
    border: 2px solid black;
  }

  .title {
    z-index: 1;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
    font-family: 'PIXEL CRAFT', sans-serif;
    font-size: 85px;
    background: -webkit-linear-gradient(#ffd900, #ed614e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .subTitle {
    font-family: 'Mrs. Monster 3D', sans-serif;
    font-size: 24px;
    text-align: center;
    color: #413f3f;
    font-weight: bold;
  }


  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }

  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }



  .boxWrap2StoryB {
    padding-top: 10%;
    padding-bottom: 10%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Story3 {
    background: url('./assets/pxArt2\ \(2\).jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    padding-top: 10%;
    border-bottom: 5px solid rgb(253, 255, 140);
  }



  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }



  .boxWrap2Mint {
    padding-bottom: 5%;
    background: url('./assets/r3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .mintUp {
    margin-top: 5%;
  }


  .boxWrap2Footer {
    padding-top: 20vh;
    padding-bottom: 20vh;
    background: url('./assets/pxArt\ \(1\).jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    background: url('./assets/pxArt3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }

  .aboutA {
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
    margin-left: auto;
    width: 95%;
    padding: 50px;

  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .about img {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .about video {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 50px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 100px;
    background: -webkit-linear-gradient(#b84ff5, #6c09a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .conT-2 {
    font-size: 50px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 10%;
    background: -webkit-linear-gradient(#69fcb5, #03a05e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .conT2 {
    font-size: 30px;
    color: #dadd55;
    z-index: 1;
    text-align: center;
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
  }

  .hl {
    color: #89e251;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Caveat', sans-serif;
    font-size: 22px;
  }

  .conTNew {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Doctor Glitch', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Doctor Glitch', sans-serif;

  }

  .conTMob {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Doctor Glitch', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Doctor Glitch', sans-serif;
  }

  .storyCon {
    font-size: 18px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: 'Lora', sans-serif;
  }

  .aboutToken p {
    color: white;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Lora', sans-serif;
    color: white;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px #81f5fd dashed;
    padding: 50px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-color: #00000038;
    flex-flow: column nowrap;
  }

  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 400px;
    height: 400px;
    border-radius: 20px;
    border: 3px solid rgb(255, 255, 255);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;

    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);

    font-family: 'Lora', sans-serif;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 40px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: 70px;
    height: 70px;
    border: none;
    cursor: pointer;
    background-image: linear-gradient(#0dccea, #0d70ea);
    border: 0;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
    border-radius: 5px;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3-3 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 90%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: no-drop;
    border-radius: 5px;
    border: 2px solid white;
  }

  .btnfos-0-3-3:hover {
    background-color: rgb(97, 142, 179);
  }

  .btnfos-0-3-3:active {
    background-color: rgb(97, 142, 179);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .btnfos-0-3 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 90%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: pointer;
    border-radius: 5px;
    border: 2px solid white;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 90px;
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    background: -webkit-linear-gradient(#b84ff5, #6c09a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #26ff00;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .name {
    font-family: 'Doctor Glitch', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 50px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 18px;
    color: whitesmoke;
  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .icons img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .footerT {
    font-family: 'Doctor Glitch', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;

  }

  .glassy {
    background-color: #000000b0;
    padding: 30px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 50px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;

  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 28px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px #c588ff;
    font-family: 'Henny Penny';
    background: -webkit-linear-gradient(#ffe89c, #f54fbe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .mobH2 {
    display: none;
  }


  .smallL {
    font-size: 18px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .special {
    font-weight: 500;
    text-shadow: 0 0 10px #bef86c;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #e3ffbc;
    font-family: 'Lora', sans-serif;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #9fdf43;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #88c647;
    border: 5px dashed #365a04;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #6aa728;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyDiv {
    display: flex;
    justify-content: space-between;
    bottom: 0;
    position: sticky;
    margin-top: -5%;
  }

  .stickyImg {
    width: 20%;
    height: 20%;
    bottom: 0;
    z-index: 1;
    position: sticky;
    margin-top: -10%;
  }

  .sticky {
    width: 20%;
    height: 20%;
    bottom: 0;
    z-index: 1;
  }

  .sticky2 {
    width: 20%;
    bottom: 0;
    z-index: 1;
  }

  .div {
    width: 100%;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 5.5px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #c723a3);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgba(107, 215, 254, 0.425);
    overflow: hidden;
    border-right: rgba(95, 158, 160, 0);

  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 100s;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 30px;
    color: #ffffff;
    background: -webkit-linear-gradient(#ffffff, #f5c5dd, #82e480);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
    font-family: 'Henny Penny';
  }

}

@media screen and (min-width: 1200px) and (max-width: 1919px) {


  @keyframes neonGlow {
    0% {
      border-color: #62b9e7;
      box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    }

    50% {
      border-color: #e82598;
      box-shadow: 0 0 10px #62b9e7, 0 0 25px #62b9e7, 0 0 35px #62b9e7;
    }

    100% {
      border-color: #62b9e7;
      box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    }
  }

  .btnfos-0-3-3 .initialText {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  }

  .btnfos-0-3-3:hover .initialText {
    opacity: 0;
    visibility: hidden;
  }

  .btnfos-0-3-3 .hoverText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    width: 90%;
  }

  .btnfos-0-3-3:hover .hoverText {
    opacity: 1;
    visibility: visible;
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .logoDiv {
    cursor: pointer;
  }


  ::-webkit-scrollbar {
    width: 15px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #96df48;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 171, 171);
  }

  .buyImg {
    width: 85px;
    margin-left: 3%;
    margin-top: 10px;
  }

  .boxWrap2Page {
    background-color: #010408;
    overflow: hidden;
  }

  .usdAmount img {
    width: 30px;
    height: 30px;
  }

  .conT4 {
    color: white;
    text-align: center;
    font-size: 30px;
    font-family: 'Doctor Glitch', sans-serif;
    margin-bottom: 2%;
    text-shadow: 0 0 10px #ffffff8a;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 81px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    opacity: 0.85;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }


  .usdAmount {
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 10px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1d2129;
    padding: 10px;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 15px #e82598, 0 0 25px #e82598;
    animation: neonGlow 2s infinite;
    /* Adjust the duration as needed */
    margin-top: 50px;
    margin-bottom: 80px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: linear-gradient(309deg, rgba(33, 43, 54, 1) 0%, rgba(20, 25, 31, 1) 50%, rgba(59, 42, 69, 1) 100%);
  }

  .usdT {
    font-family: 'Doctor Glitch', sans-serif;
    font-size: 22px;
  }

  .usd2 {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 90%;
  }

  .logo2Mobile {
    display: none;
  }

  .logoDivMob {
    display: none;
  }

  .token {
    width: 40%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;

  }

  .d1 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
  }

  .square {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  .glitchLoad {
    font-size: 30px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Doctor Glitch', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }


  .tokenomicsCon {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff934;
    padding: 20px;
    width: 90%;
    color: black;
    box-shadow: 0 5px rgb(245, 25, 54);
  }

  .tokenLink {

    font-size: 20px;
    font-weight: 500;
  }

  .tokenLink2 {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  .about3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 7%;

  }

  .videoMain {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .aboutToken {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 55%;
    background-color: #00000077;
    padding: 30px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 50px;
    border: 5px dashed;
    margin-bottom: 70px;
    animation: colorChange 5s infinite alternate;
  }

  @keyframes colorChange {
    0% {
      border-color: #93e001;
    }

    25% {
      border-color: #e0c401;
    }

    50% {
      border-color: #7418eb;
    }

    75% {
      border-color: #ff5a27;
    }

    100% {
      border-color: #f78ec3;
    }
  }


  .punkSubH {
    color: rgb(192, 251, 255);
    margin-bottom: 50px;
    width: 50%;
    margin-left: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #0000003a;
    border-radius: 20px;
    padding: 10px;
    margin-top: 3%;
    font-family: 'Caveat', sans-serif;
    font-size: 30px;
    border: 4px dashed #c579ff;
  }

  .stickyRm {
    position: sticky;
    bottom: 0;
    margin-top: -10%;
    width: 40%;
    margin-left: 60%;
  }

  .bg {
    background-position: center;
    width: 100%;
    height: min-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 18px;
    cursor: pointer;

    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: 'PIXEL CRAFT', sans-serif;
    letter-spacing: 2px;
    -webkit-text-stroke: 1px black;
  }

  #fontSize:hover {
    color: #d03fe6;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  /* CSS */
  .button-71 {
    background-color: #d03fe6;
    border: 0;
    border-radius: 56px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: 'PIXEL CRAFT', sans-serif;
    font-size: 18px;
    font-weight: 600;
    outline: 0;
    padding: 16px 21px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 10px;
    letter-spacing: 2px;

  }

  .button-71:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 92%;
  }

  .button-71:hover {
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    .button-71 {
      padding: 16px 48px;
    }
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-top: 10px;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 3px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .cont {
    background: url('./assets/r1.jpg') center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }



  .introduction {
    margin-bottom: 15vh;

  }

  .dinos {
    display: flex;
    justify-content: center;
  }

  .dinos img {
    width: 100%;
    margin-top: -15vh;
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
  }

  .logoDiv img {
    width: 80px;
    border-radius: 100%;
    border: 2px solid black;
  }

  .title {
    z-index: 1;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
    font-family: 'PIXEL CRAFT', sans-serif;
    font-size: 100px;
    background: -webkit-linear-gradient(#ffd900, #ed614e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .subTitle {
    font-family: 'Mrs. Monster 3D', sans-serif;
    font-size: 25px;
    text-align: center;
    color: #413f3f;
    font-weight: bold;
  }


  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }

  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }



  .boxWrap2StoryB {
    padding-top: 10%;
    padding-bottom: 10%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Story3 {
    background: url('./assets/pxArt2\ \(2\).jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    padding-top: 10%;
    border-bottom: 5px solid rgb(253, 255, 140);
  }



  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }



  .boxWrap2Mint {
    padding-bottom: 5%;
    background: url('./assets/r3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .mintUp {
    margin-top: 5%;
  }


  .boxWrap2Footer {
    padding-top: 20vh;
    padding-bottom: 20vh;
    background: url('./assets/pxArt\ \(1\).jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    background: url('./assets/pxArt3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }

  .aboutA {
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
    margin-left: auto;
    width: 95%;
    padding: 50px;

  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .about img {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .about video {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 60px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 100px;
    background: -webkit-linear-gradient(#b84ff5, #6c09a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .conT-2 {
    font-size: 60px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 100px;
    background: -webkit-linear-gradient(#69fcb5, #03a05e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .conT2 {
    font-size: 30px;
    color: #dadd55;
    z-index: 1;
    text-align: center;
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
  }

  .hl {
    color: #89e251;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Caveat', sans-serif;
    font-size: 22px;
  }

  .conTNew {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Doctor Glitch', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Doctor Glitch', sans-serif;

  }

  .conTMob {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Doctor Glitch', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Doctor Glitch', sans-serif;
  }

  .storyCon {
    font-size: 18px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: 'Lora', sans-serif;
  }

  .aboutToken p {
    color: white;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Lora', sans-serif;
    color: white;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px #81f5fd dashed;
    padding: 50px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-color: #00000038;
  }

  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 400px;
    height: 400px;
    border-radius: 20px;
    border: 3px solid rgb(255, 255, 255);
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;

    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);

    font-family: 'Lora', sans-serif;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 40px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: 70px;
    height: 70px;
    border: none;
    cursor: pointer;
    background-image: linear-gradient(#0dccea, #0d70ea);
    border: 0;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
    border-radius: 5px;
  }

  .btnfos-0-2-2 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 90%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: pointer;
    border-radius: 5px;
    border: 2px solid white;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3-3 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 90%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: no-drop;
    border-radius: 5px;
    border: 2px solid white;
  }

  .btnfos-0-3-3:hover {
    background-color: rgb(97, 142, 179);
  }

  .btnfos-0-3-3:active {
    background-color: rgb(97, 142, 179);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .btnfos-0-3 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 90%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: pointer;
    border-radius: 5px;
    border: 2px solid white;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 90px;
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    background: -webkit-linear-gradient(#b84ff5, #6c09a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #26ff00;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .name {
    font-family: 'Doctor Glitch', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 50px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 18px;
    color: whitesmoke;
  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .icons img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .footerT {
    font-family: 'Doctor Glitch', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;

  }

  .glassy {
    background-color: #000000b0;
    padding: 30px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 50px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;

  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px #c588ff;
    font-family: 'Henny Penny';
    background: -webkit-linear-gradient(#ffe89c, #f54fbe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .mobH2 {
    display: none;
  }

  .smallL {
    font-size: 18px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .special {
    font-weight: 500;
    text-shadow: 0 0 10px #bef86c;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #e3ffbc;
    font-family: 'Lora', sans-serif;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #9fdf43;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #88c647;
    border: 5px dashed #365a04;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #6aa728;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyDiv {
    display: flex;
    justify-content: space-between;
    bottom: 0;
    position: sticky;
    margin-top: -5%;
  }

  .stickyImg {
    width: 20%;
    height: 20%;
    bottom: 0;
    z-index: 1;
    position: sticky;
    margin-top: -10%;
  }

  .sticky {
    width: 20%;
    height: 20%;
    bottom: 0;
    z-index: 1;
  }

  .sticky2 {
    width: 20%;
    bottom: 0;
    z-index: 1;
  }

  .div {
    width: 100%;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 5.5px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-right: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #c723a3);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgba(107, 215, 254, 0.425);
    overflow: hidden;
    border-right: rgba(95, 158, 160, 0);

  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 100s;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 30px;
    color: #ffffff;
    background: -webkit-linear-gradient(#ffffff, #f5c5dd, #82e480);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
    font-family: 'Henny Penny';
  }

}

@media screen and (min-width: 1920px) {


  @keyframes neonGlow {
    0% {
      border-color: #62b9e7;
      box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    }

    50% {
      border-color: #e82598;
      box-shadow: 0 0 10px #62b9e7, 0 0 25px #62b9e7, 0 0 35px #62b9e7;
    }

    100% {
      border-color: #62b9e7;
      box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    }
  }

  .btnfos-0-3-3 .initialText {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  }

  .btnfos-0-3-3:hover .initialText {
    opacity: 0;
    visibility: hidden;
  }

  .btnfos-0-3-3 .hoverText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    width: 90%;
  }

  .btnfos-0-3-3:hover .hoverText {
    opacity: 1;
    visibility: visible;
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .logoDiv {
    cursor: pointer;
  }


  ::-webkit-scrollbar {
    width: 15px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #96df48;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 171, 171);
  }

  .buyImg {
    width: 85px;
    margin-left: 3%;
    margin-top: 10px;
  }

  .boxWrap2Page {
    background-color: #010408;
    overflow: hidden;
  }

  .usdAmount img {
    width: 30px;
    height: 30px;
  }

  .conT4 {
    color: white;
    text-align: center;
    font-size: 30px;
    font-family: 'Doctor Glitch', sans-serif;
    margin-bottom: 2%;
    text-shadow: 0 0 10px #ffffff8a;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 81px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    opacity: 0.85;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }


  .usdAmount {
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 10px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1d2129;
    padding: 10px;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 15px #e82598, 0 0 25px #e82598;
    animation: neonGlow 2s infinite;
    /* Adjust the duration as needed */
    margin-top: 50px;
    margin-bottom: 80px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: linear-gradient(309deg, rgba(33, 43, 54, 1) 0%, rgba(20, 25, 31, 1) 50%, rgba(59, 42, 69, 1) 100%);
  }

  .usdT {
    font-family: 'Doctor Glitch', sans-serif;
    font-size: 22px;
  }

  .usd2 {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 90%;
  }

  .logo2Mobile {
    display: none;
  }

  .logoDivMob {
    display: none;
  }

  .token {
    width: 40%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;

  }

  .d1 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
  }

  .square {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  .glitchLoad {
    font-size: 30px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Doctor Glitch', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }


  .tokenomicsCon {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff934;
    padding: 20px;
    width: 90%;
    color: black;
    box-shadow: 0 5px rgb(245, 25, 54);
  }

  .tokenLink {

    font-size: 20px;
    font-weight: 500;
  }

  .tokenLink2 {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  .about3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 7%;

  }

  .videoMain {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .aboutToken {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 55%;
    background-color: #00000077;
    padding: 30px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 80px;
    border: 8px dashed;
    margin-bottom: 100px;
    animation: colorChange 5s infinite alternate;
  }

  @keyframes colorChange {
    0% {
      border-color: #93e001;
    }

    25% {
      border-color: #e0c401;
    }

    50% {
      border-color: #7418eb;
    }

    75% {
      border-color: #ff5a27;
    }

    100% {
      border-color: #f78ec3;
    }
  }


  .punkSubH {
    color: rgb(192, 251, 255);
    margin-bottom: 80px;
    width: 50%;
    margin-left: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #0000003a;
    border-radius: 25px;
    padding: 18px;
    margin-top: 3%;
    font-family: 'Caveat', sans-serif;
    font-size: 40px;
    border: 6px dashed #c579ff;
  }

  .stickyRm {
    position: sticky;
    bottom: 0;
    margin-top: -10%;
    width: 40%;
    margin-left: 60%;
  }

  .bg {
    background-position: center;
    width: 100%;
    height: min-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding-top: 25px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 28px;
    cursor: pointer;

    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: 'PIXEL CRAFT', sans-serif;
    letter-spacing: 4px;
    -webkit-text-stroke: 1.5px black;
  }

  #fontSize:hover {
    color: #d03fe6;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  /* CSS */
  .button-71 {
    background-color: #d03fe6;
    border: 0;
    border-radius: 56px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: 'PIXEL CRAFT', sans-serif;
    font-size: 24px;
    font-weight: 600;
    outline: 0;
    padding: 25px 30px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 12px;
    letter-spacing: 4px;

  }

  .button-71:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 92%;
  }

  .button-71:hover {
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    .button-71 {
      padding: 16px 48px;
    }
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-top: 10px;
  }

  .socialIcon img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 3px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: url('./assets/r1.jpg') center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }



  .introduction {
    margin-bottom: 15vh;

  }

  .dinos {
    display: flex;
    justify-content: center;
  }

  .dinos img {
    width: 100%;
    margin-top: -5vh;
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
  }

  .logoDiv img {
    width: 130px;
    border-radius: 100%;
    border: 3px solid black;
  }

  .title {
    z-index: 1;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
    font-family: 'PIXEL CRAFT', sans-serif;
    font-size: 140px;
    background: -webkit-linear-gradient(#ffd900, #ed614e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .subTitle {
    font-family: 'Mrs. Monster 3D', sans-serif;
    font-size: 35px;
    text-align: center;
    color: #413f3f;
    font-weight: bold;
  }


  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }

  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }



  .boxWrap2StoryB {
    padding-top: 10%;
    padding-bottom: 10%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Story3 {
    background: url('./assets/pxArt2\ \(2\).jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    padding-top: 10%;
    border-bottom: 8px solid rgb(253, 255, 140);
  }



  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }



  .boxWrap2Mint {
    padding-bottom: 5%;
    background: url('./assets/r3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .mintUp {
    margin-top: 5%;
  }


  .boxWrap2Footer {
    padding-top: 20vh;
    padding-bottom: 20vh;
    background: url('./assets/pxArt\ \(1\).jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    background: url('./assets/pxArt3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }

  .aboutA {
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
    margin-left: auto;
    width: 95%;
    padding: 50px;

  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .about img {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .about video {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 85px;
    color: rgb(255, 255, 255);
    letter-spacing: 4px;
    font-family: 'PIXEL CRAFT', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 125px;
    background: -webkit-linear-gradient(#b84ff5, #6c09a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black;
  }

  .conT-2 {
    font-size: 85px;
    color: rgb(255, 255, 255);
    letter-spacing: 4px;
    font-family: 'PIXEL CRAFT', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 125px;
    background: -webkit-linear-gradient(#69fcb5, #03a05e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black;
  }

  .conT2 {
    font-size: 42px;
    color: #dadd55;
    z-index: 1;
    text-align: center;
    letter-spacing: 4px;
    font-family: 'PIXEL CRAFT', sans-serif;
  }

  .hl {
    color: #89e251;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Caveat', sans-serif;
    font-size: 30px;
  }

  .conTNew {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Doctor Glitch', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Doctor Glitch', sans-serif;

  }

  .conTMob {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Doctor Glitch', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Doctor Glitch', sans-serif;
  }

  .storyCon {
    font-size: 18px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: 'Lora', sans-serif;
  }

  .aboutToken p {
    color: white;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Lora', sans-serif;
    color: white;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 7px #81f5fd dashed;
    padding: 50px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-color: #00000038;
  }

  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 500px;
    height: 500px;
    border-radius: 20px;
    border: 4px solid rgb(255, 255, 255);
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 65px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;

    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 28px;
    color: rgb(255, 255, 255);

    font-family: 'Lora', sans-serif;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 40px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 50px;
    margin-left: 50px;
    margin-right: 50px;
    width: 90px;
    height: 90px;
    border: none;
    cursor: pointer;
    background-image: linear-gradient(#0dccea, #0d70ea);
    border: 0;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 3px solid;
    border-radius: 7px;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3-3 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 15px;
    font-size: 35px;
    width: 100%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -60px;
    cursor: no-drop;
    border-radius: 7px;
    border: 3px solid white;
  }

  .btnfos-0-3-3:hover {
    background-color: rgb(97, 142, 179);
  }

  .btnfos-0-3-3:active {
    background-color: rgb(97, 142, 179);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .btnfos-0-3 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 15px;
    font-size: 35px;
    width: 100%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -60px;
    cursor: pointer;
    border-radius: 7px;
    border: 3px solid white;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 120px;
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    background: -webkit-linear-gradient(#b84ff5, #6c09a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2.5px;
    -webkit-text-stroke-color: black;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #26ff00;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 30px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 23px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 28px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 28px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .name {
    font-family: 'Doctor Glitch', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 50px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 18px;
    color: whitesmoke;
  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .icons img {
    width: 75px;
    height: 75px;
    margin-left: 30px;
    margin-right: 30px;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 230px;
    height: 230px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 24px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .footerT {
    font-family: 'Doctor Glitch', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;

  }

  .glassy {
    background-color: #000000b0;
    padding: 30px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 80px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 900px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;

  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 42px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px #c588ff;
    font-family: 'Henny Penny';
    background: -webkit-linear-gradient(#ffe89c, #f54fbe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .mobH2 {
    display: none;
  }

  .smallL {
    font-size: 24px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .special {
    font-weight: 500;
    text-shadow: 0 0 10px #bef86c;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 24px;
    padding: 10px;
    border-radius: 10px;
    text-shadow: 0 0 10px #e3ffbc;
    font-family: 'Lora', sans-serif;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 90px;
    height: 90px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 12px;
    background-color: #9fdf43;
    left: 55px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 12px;
    background-color: #c5c3cb8a;
    left: 55px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #88c647;
    border: 8px dashed #365a04;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #6aa728;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 50px;
    height: 50px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyDiv {
    display: flex;
    justify-content: space-between;
    bottom: 0;
    position: sticky;
    margin-top: -5%;
  }

  .stickyImg {
    width: 20%;
    height: 20%;
    bottom: 0;
    z-index: 1;
    position: sticky;
    margin-top: -10%;
  }

  .sticky {
    width: 20%;
    height: 20%;
    bottom: 0;
    z-index: 1;
  }

  .sticky2 {
    width: 20%;
    bottom: 0;
    z-index: 1;
  }

  .div {
    width: 100%;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 7.5rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 7px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #c723a3);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgba(107, 215, 254, 0.425);
    overflow: hidden;
    border-right: rgba(95, 158, 160, 0);
    border-right: rgba(95, 158, 160, 0);


  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 7.5rem;
    line-height: 7.5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 100s;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 48px;
    color: #ffffff;
    background: -webkit-linear-gradient(#ffffff, #f5c5dd, #82e480);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 4px;
    font-family: 'Henny Penny';
  }

}

@media screen and (min-width: 1921px) and (max-width: 3839px) {


  @keyframes neonGlow {
    0% {
      border-color: #62b9e7;
      box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    }

    50% {
      border-color: #e82598;
      box-shadow: 0 0 10px #62b9e7, 0 0 25px #62b9e7, 0 0 35px #62b9e7;
    }

    100% {
      border-color: #62b9e7;
      box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    }
  }

  .btnfos-0-3-3 .initialText {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  }

  .btnfos-0-3-3:hover .initialText {
    opacity: 0;
    visibility: hidden;
  }

  .btnfos-0-3-3 .hoverText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    width: 90%;
  }

  .btnfos-0-3-3:hover .hoverText {
    opacity: 1;
    visibility: visible;
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .logoDiv {
    cursor: pointer;
  }


  ::-webkit-scrollbar {
    width: 15px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #96df48;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 171, 171);
  }

  .buyImg {
    width: 85px;
    margin-left: 3%;
    margin-top: 10px;
  }

  .boxWrap2Page {
    background-color: #010408;
    overflow: hidden;
  }

  .usdAmount img {
    width: 30px;
    height: 30px;
  }

  .conT4 {
    color: white;
    text-align: center;
    font-size: 30px;
    font-family: 'Doctor Glitch', sans-serif;
    margin-bottom: 2%;
    text-shadow: 0 0 10px #ffffff8a;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 81px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    opacity: 0.85;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }


  .usdAmount {
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 10px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1d2129;
    padding: 10px;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 15px #e82598, 0 0 25px #e82598;
    animation: neonGlow 2s infinite;
    /* Adjust the duration as needed */
    margin-top: 50px;
    margin-bottom: 80px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: linear-gradient(309deg, rgba(33, 43, 54, 1) 0%, rgba(20, 25, 31, 1) 50%, rgba(59, 42, 69, 1) 100%);
  }

  .usdT {
    font-family: 'Doctor Glitch', sans-serif;
    font-size: 22px;
  }

  .usd2 {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 90%;
  }

  .logo2Mobile {
    display: none;
  }

  .logoDivMob {
    display: none;
  }

  .token {
    width: 40%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;

  }

  .d1 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
  }

  .square {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  .glitchLoad {
    font-size: 30px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Doctor Glitch', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }


  .tokenomicsCon {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff934;
    padding: 20px;
    width: 90%;
    color: black;
    box-shadow: 0 5px rgb(245, 25, 54);
  }

  .tokenLink {

    font-size: 20px;
    font-weight: 500;
  }

  .tokenLink2 {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  .about3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 7%;

  }

  .videoMain {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .aboutToken {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 55%;
    background-color: #00000077;
    padding: 40px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 80px;
    border: 10px dashed;
    margin-bottom: 125px;
    animation: colorChange 5s infinite alternate;
  }

  @keyframes colorChange {
    0% {
      border-color: #93e001;
    }

    25% {
      border-color: #e0c401;
    }

    50% {
      border-color: #7418eb;
    }

    75% {
      border-color: #ff5a27;
    }

    100% {
      border-color: #f78ec3;
    }
  }


  .punkSubH {
    color: rgb(192, 251, 255);
    margin-bottom: 120px;
    width: 50%;
    margin-left: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #0000003a;
    border-radius: 45px;
    padding: 24px;
    margin-top: 3%;
    font-family: 'Caveat', sans-serif;
    font-size: 55px;
    border: 8px dashed #c579ff;
  }

  .stickyRm {
    position: sticky;
    bottom: 0;
    margin-top: -10%;
    width: 40%;
    margin-left: 60%;
  }

  .bg {
    background-position: center;
    width: 100%;
    height: min-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 32px;
    cursor: pointer;

    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: 'PIXEL CRAFT', sans-serif;
    letter-spacing: 6px;
    -webkit-text-stroke: 2px black;
  }

  #fontSize:hover {
    color: #d03fe6;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  /* CSS */
  .button-71 {
    background-color: #d03fe6;
    border: 0;
    border-radius: 80px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: 'PIXEL CRAFT', sans-serif;
    font-size: 32px;
    font-weight: 600;
    outline: 0;
    padding: 35px;
    width: 250px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 12px;
    letter-spacing: 4px;

  }

  .button-71:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 92%;
  }

  .button-71:hover {
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    .button-71 {
      padding: 16px 48px;
    }
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-top: 10px;
  }

  .socialIcon img {
    width: 55px;
    height: 55px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 3px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: url('./assets/r1.jpg') center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }



  .introduction {
    margin-bottom: 15vh;

  }

  .dinos {
    display: flex;
    justify-content: center;
  }

  .dinos img {
    width: 100%;
    margin-top: -8vh;
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
  }

  .logoDiv img {
    width: 185px;
    border-radius: 100%;
    border: 4px solid black;
  }

  .title {
    z-index: 1;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
    font-family: 'PIXEL CRAFT', sans-serif;
    font-size: 190px;
    background: -webkit-linear-gradient(#ffd900, #ed614e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 3.5px;
    -webkit-text-stroke-color: black;
  }

  .subTitle {
    font-family: 'Mrs. Monster 3D', sans-serif;
    font-size: 45px;
    text-align: center;
    color: #413f3f;
    font-weight: bold;
  }


  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }

  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }



  .boxWrap2StoryB {
    padding-top: 10%;
    padding-bottom: 10%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Story3 {
    background: url('./assets/pxArt2\ \(2\).jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    padding-top: 10%;
    border-bottom: 10px solid rgb(253, 255, 140);
  }



  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }



  .boxWrap2Mint {
    padding-bottom: 5%;
    background: url('./assets/r3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .mintUp {
    margin-top: 5%;
  }


  .boxWrap2Footer {
    padding-top: 20vh;
    padding-bottom: 20vh;
    background: url('./assets/pxArt\ \(1\).jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    background: url('./assets/pxArt3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }

  .aboutA {
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
    margin-left: auto;
    width: 95%;
    padding: 50px;

  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .about img {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .about video {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 120px;
    color: rgb(255, 255, 255);
    letter-spacing: 5px;
    font-family: 'PIXEL CRAFT', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 125px;
    background: -webkit-linear-gradient(#b84ff5, #6c09a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: black;
  }

  .conT-2 {
    font-size: 120px;
    color: rgb(255, 255, 255);
    letter-spacing: 5px;
    font-family: 'PIXEL CRAFT', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 125px;
    background: -webkit-linear-gradient(#69fcb5, #03a05e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: black;
  }

  .conT2 {
    font-size: 55px;
    color: #dadd55;
    z-index: 1;
    text-align: center;
    letter-spacing: 5px;
    font-family: 'PIXEL CRAFT', sans-serif;
  }

  .hl {
    color: #89e251;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Caveat', sans-serif;
    font-size: 45px;
  }

  .conTNew {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Doctor Glitch', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Doctor Glitch', sans-serif;

  }

  .conTMob {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Doctor Glitch', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Doctor Glitch', sans-serif;
  }

  .storyCon {
    font-size: 18px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: 'Lora', sans-serif;
  }

  .aboutToken p {
    color: white;
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Lora', sans-serif;
    color: white;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 10px #81f5fd dashed;
    padding: 100px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-color: #00000038;
  }

  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 680px;
    height: 680px;
    border-radius: 40px;
    border: 5px solid rgb(255, 255, 255);
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;

    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 35px;
    color: rgb(255, 255, 255);

    font-family: 'Lora', sans-serif;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 40px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 90px;
    margin-right: 90px;
    width: 120px;
    height: 120px;
    border: none;
    cursor: pointer;
    background-image: linear-gradient(#0dccea, #0d70ea);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 4px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3-3 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 18px;
    font-size: 40px;
    width: 90%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    cursor: no-drop;
    border-radius: 10px;
    border: 5px solid white;
    margin-top: 10px;
  }

  .btnfos-0-3-3:hover {
    background-color: rgb(97, 142, 179);
  }

  .btnfos-0-3-3:active {
    background-color: rgb(97, 142, 179);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .btnfos-0-3 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 18px;
    font-size: 40px;
    width: 90%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    cursor: pointer;
    border-radius: 10px;
    border: 5px solid white;
    margin-top: 10px;

  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 150px;
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    background: -webkit-linear-gradient(#b84ff5, #6c09a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #26ff00;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 40px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 30px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 37px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 37px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .name {
    font-family: 'Doctor Glitch', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 50px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 18px;
    color: whitesmoke;
  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .icons img {
    width: 90px;
    height: 90px;
    margin-left: 40px;
    margin-right: 40px;
    transition: transform .6s;
    margin-bottom: 10px;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 280px;
    height: 280px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 32px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .footerT {
    font-family: 'Doctor Glitch', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;

  }

  .glassy {
    background-color: #000000b0;
    padding: 30px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 80px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 1200px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;

  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 55px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px #c588ff;
    font-family: 'Henny Penny';
    background: -webkit-linear-gradient(#ffe89c, #f54fbe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .mobH2 {
    display: none;
  }

  .smallL {
    font-size: 32px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .special {
    font-weight: 500;
    text-shadow: 0 0 10px #bef86c;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 32px;
    padding: 10px;
    border-radius: 10px;
    text-shadow: 0 0 10px #e3ffbc;
    font-family: 'Lora', sans-serif;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 90px;
    height: 90px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 12px;
    background-color: #9fdf43;
    left: 55px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 12px;
    background-color: #c5c3cb8a;
    left: 55px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #88c647;
    border: 8px dashed #365a04;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #6aa728;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 50px;
    height: 50px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyDiv {
    display: flex;
    justify-content: space-between;
    bottom: 0;
    position: sticky;
    margin-top: -5%;
  }

  .stickyImg {
    width: 20%;
    height: 20%;
    bottom: 0;
    z-index: 1;
    position: sticky;
    margin-top: -10%;
  }

  .sticky {
    width: 20%;
    height: 20%;
    bottom: 0;
    z-index: 1;
  }

  .sticky2 {
    width: 20%;
    bottom: 0;
    z-index: 1;
  }

  .div {
    width: 100%;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 9rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 10px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #c723a3);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgba(107, 215, 254, 0.425);
    overflow: hidden;
    border-right: rgba(95, 158, 160, 0);

  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 9rem;
    line-height: 9rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 100s;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 52px;
    color: #ffffff;
    background: -webkit-linear-gradient(#ffffff, #f5c5dd, #82e480);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 5px;
    font-family: 'Henny Penny';
  }

}

@media screen and (min-width: 3840px) {


  @keyframes neonGlow {
    0% {
      border-color: #62b9e7;
      box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    }

    50% {
      border-color: #e82598;
      box-shadow: 0 0 10px #62b9e7, 0 0 25px #62b9e7, 0 0 35px #62b9e7;
    }

    100% {
      border-color: #62b9e7;
      box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    }
  }

  .btnfos-0-3-3 .initialText {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  }

  .btnfos-0-3-3:hover .initialText {
    opacity: 0;
    visibility: hidden;
  }

  .btnfos-0-3-3 .hoverText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    width: 90%;
  }

  .btnfos-0-3-3:hover .hoverText {
    opacity: 1;
    visibility: visible;
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .logoDiv {
    cursor: pointer;
  }


  ::-webkit-scrollbar {
    width: 15px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #96df48;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 171, 171);
  }

  .buyImg {
    width: 85px;
    margin-left: 3%;
    margin-top: 10px;
  }

  .boxWrap2Page {
    background-color: #010408;
    overflow: hidden;
  }

  .usdAmount img {
    width: 30px;
    height: 30px;
  }

  .conT4 {
    color: white;
    text-align: center;
    font-size: 30px;
    font-family: 'Doctor Glitch', sans-serif;
    margin-bottom: 2%;
    text-shadow: 0 0 10px #ffffff8a;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 81px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    opacity: 0.85;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }


  .usdAmount {
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 10px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1d2129;
    padding: 10px;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 15px #e82598, 0 0 25px #e82598;
    animation: neonGlow 2s infinite;
    /* Adjust the duration as needed */
    margin-top: 50px;
    margin-bottom: 80px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: linear-gradient(309deg, rgba(33, 43, 54, 1) 0%, rgba(20, 25, 31, 1) 50%, rgba(59, 42, 69, 1) 100%);
  }

  .usdT {
    font-family: 'Doctor Glitch', sans-serif;
    font-size: 22px;
  }

  .usd2 {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 90%;
  }

  .logo2Mobile {
    display: none;
  }

  .logoDivMob {
    display: none;
  }

  .token {
    width: 40%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;

  }

  .d1 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
  }

  .square {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  .glitchLoad {
    font-size: 30px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Doctor Glitch', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }


  .tokenomicsCon {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff934;
    padding: 20px;
    width: 90%;
    color: black;
    box-shadow: 0 5px rgb(245, 25, 54);
  }

  .tokenLink {

    font-size: 20px;
    font-weight: 500;
  }

  .tokenLink2 {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  .about3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 7%;

  }

  .videoMain {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .aboutToken {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 55%;
    background-color: #00000077;
    padding: 80px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 100px;
    border: 12px dashed;
    margin-bottom: 200px;
    animation: colorChange 5s infinite alternate;
  }

  @keyframes colorChange {
    0% {
      border-color: #93e001;
    }

    25% {
      border-color: #e0c401;
    }

    50% {
      border-color: #7418eb;
    }

    75% {
      border-color: #ff5a27;
    }

    100% {
      border-color: #f78ec3;
    }
  }


  .punkSubH {
    color: rgb(192, 251, 255);
    margin-bottom: 120px;
    width: 50%;
    margin-left: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #0000003a;
    border-radius: 55px;
    padding: 35px;
    margin-top: 3%;
    font-family: 'Caveat', sans-serif;
    font-size: 80px;
    border: 10px dashed #c579ff;
  }

  .stickyRm {
    position: sticky;
    bottom: 0;
    margin-top: -10%;
    width: 40%;
    margin-left: 60%;
  }

  .bg {
    background-position: center;
    width: 100%;
    height: min-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding-top: 60px;
    padding-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 55px;
    cursor: pointer;

    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: 'PIXEL CRAFT', sans-serif;
    letter-spacing: 6px;
    -webkit-text-stroke: 3px black;
  }

  #fontSize:hover {
    color: #d03fe6;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 70px;
    margin-right: 70px;
  }

  /* CSS */
  .button-71 {
    background-color: #d03fe6;
    border: 0;
    border-radius: 80px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: 'PIXEL CRAFT', sans-serif;
    font-size: 45px;
    font-weight: 600;
    outline: 0;
    padding: 35px;
    width: 300px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 12px;
    letter-spacing: 4px;

  }

  .button-71:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 92%;
  }

  .button-71:hover {
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    .button-71 {
      padding: 16px 48px;
    }
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-top: 10px;
  }

  .socialIcon img {
    width: 90px;
    height: 90px;
    margin-left: 35px;
    margin-right: 35px;
    margin-top: 3px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: url('./assets/r1.jpg') center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }



  .introduction {
    margin-bottom: 15vh;

  }

  .dinos {
    display: flex;
    justify-content: center;
  }

  .dinos img {
    width: 100%;
    margin-top: -5vh;
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
  }

  .logoDiv img {
    width: 280px;
    border-radius: 100%;
    border: 8px solid black;
  }

  .title {
    z-index: 1;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8vh;
    font-family: 'PIXEL CRAFT', sans-serif;
    font-size: 280px;
    background: -webkit-linear-gradient(#ffd900, #ed614e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 3.5px;
    -webkit-text-stroke-color: black;
  }

  .subTitle {
    font-family: 'Mrs. Monster 3D', sans-serif;
    font-size: 70px;
    text-align: center;
    color: #413f3f;
    font-weight: bold;
  }


  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }

  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }



  .boxWrap2StoryB {
    padding-top: 10%;
    padding-bottom: 10%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Story3 {
    background: url('./assets/pxArt2\ \(2\).jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    padding-top: 10%;
    border-bottom: 10px solid rgb(253, 255, 140);
  }



  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }



  .boxWrap2Mint {
    padding-bottom: 5%;
    background: url('./assets/r3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .mintUp {
    margin-top: 5%;
  }


  .boxWrap2Footer {
    padding-top: 20vh;
    padding-bottom: 20vh;
    background: url('./assets/pxArt\ \(1\).jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    background: url('./assets/pxArt3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }

  .aboutA {
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
    margin-left: auto;
    width: 95%;
    padding: 50px;

  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .about img {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .about video {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 170px;
    color: rgb(255, 255, 255);
    letter-spacing: 7px;
    font-family: 'PIXEL CRAFT', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 180px;
    background: -webkit-linear-gradient(#b84ff5, #6c09a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 5.5px;
    -webkit-text-stroke-color: black;
  }

  .conT-2 {
    font-size: 170px;
    color: rgb(255, 255, 255);
    letter-spacing: 7px;
    font-family: 'PIXEL CRAFT', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 125px;
    background: -webkit-linear-gradient(#69fcb5, #03a05e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 5.5px;
    -webkit-text-stroke-color: black;
  }

  .conT2 {
    font-size: 80px;
    color: #dadd55;
    z-index: 1;
    text-align: center;
    letter-spacing: 5px;
    font-family: 'PIXEL CRAFT', sans-serif;
  }

  .hl {
    color: #89e251;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Caveat', sans-serif;
    font-size: 65px;
  }

  .conTNew {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Doctor Glitch', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Doctor Glitch', sans-serif;

  }

  .conTMob {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Doctor Glitch', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Doctor Glitch', sans-serif;
  }

  .storyCon {
    font-size: 18px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: 'Lora', sans-serif;
  }

  .aboutToken p {
    color: white;
    font-size: 45px;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Lora', sans-serif;
    color: white;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 15px #81f5fd dashed;
    padding: 100px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-color: #00000038;
  }

  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 1000px;
    height: 1000px;
    border-radius: 50px;
    border: 8px solid rgb(255, 255, 255);
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 120px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;

    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 55px;
    color: rgb(255, 255, 255);

    font-family: 'Lora', sans-serif;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 40px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 80px;
    margin-left: 120px;
    margin-right: 120px;
    width: 180px;
    height: 180px;
    border: none;
    cursor: pointer;
    background-image: linear-gradient(#0dccea, #0d70ea);
    border: 0;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 8px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3-3 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 25px;
    font-size: 60px;
    width: 90%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    cursor: no-drop;
    border-radius: 15px;
    border: 8px solid white;
    margin-top: 15px;
  }

  .btnfos-0-3-3:hover {
    background-color: rgb(97, 142, 179);
  }

  .btnfos-0-3-3:active {
    background-color: rgb(97, 142, 179);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .btnfos-0-3 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    color: rgb(0, 0, 0);
    padding: 25px;
    font-size: 60px;
    width: 90%;
    color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    cursor: pointer;
    border-radius: 15px;
    border: 8px solid white;
    margin-top: 15px;

  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 200px;
    letter-spacing: 2px;
    font-family: 'PIXEL CRAFT', sans-serif;
    background: -webkit-linear-gradient(#b84ff5, #6c09a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: black;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #26ff00;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 50px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 40px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 60px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 60px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .name {
    font-family: 'Doctor Glitch', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 50px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 18px;
    color: whitesmoke;
  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .icons img {
    width: 120px;
    height: 120px;
    margin-left: 40px;
    margin-right: 40px;
    transition: transform .6s;
    margin-bottom: 20px;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 450px;
    height: 450px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 45px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .footerT {
    font-family: 'Doctor Glitch', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;

  }

  .glassy {
    background-color: #000000b0;
    padding: 30px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 80px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 1500px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;

  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 85px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px #c588ff;
    font-family: 'Henny Penny';
    background: -webkit-linear-gradient(#ffe89c, #f54fbe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 90px;
  }

  .mobH2 {
    display: none;
  }

  .smallL {
    font-size: 45px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .special {
    font-weight: 500;
    text-shadow: 0 0 10px #bef86c;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 45px;
    padding: 10px;
    border-radius: 10px;
    text-shadow: 0 0 10px #e3ffbc;
    font-family: 'Lora', sans-serif;
    margin-top: 80px;
    margin-left: 90px;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 140px;
    height: 140px;
    border: 8px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 16px;
    background-color: #9fdf43;
    left: 82px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 16px;
    background-color: #c5c3cb8a;
    left: 82px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #88c647;
    border: 12px dashed #365a04;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #6aa728;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 110px;
    height: 110px;
    margin-right: 20px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyDiv {
    display: flex;
    justify-content: space-between;
    bottom: 0;
    position: sticky;
    margin-top: -5%;
  }

  .stickyImg {
    width: 20%;
    height: 20%;
    bottom: 0;
    z-index: 1;
    position: sticky;
    margin-top: -10%;
  }

  .sticky {
    width: 20%;
    height: 20%;
    bottom: 0;
    z-index: 1;
  }

  .sticky2 {
    width: 20%;
    bottom: 0;
    z-index: 1;
  }

  .div {
    width: 100%;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 14rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 12px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #c723a3);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgba(107, 215, 254, 0.425);
    overflow: hidden;
    border-right: rgba(95, 158, 160, 0);

  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 14rem;
    line-height: 14rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 100s;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 4rem;
    font-size: 80px;
    color: #ffffff;
    background: -webkit-linear-gradient(#ffffff, #f5c5dd, #82e480);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 7px;
    font-family: 'Henny Penny';
  }

}


.fadeOut {
  animation: fade 10s ease-in 0s forwards;
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

.anim-fade-out {
  animation: fade-out 1s forwards;
}

.fire {
  width: 70px;
  height: 70px;
  margin-right: 10px;
}

.fireGlow {
  animation: glow 2s ease-in-out infinite alternate;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin-right: 10px;
}

.firefly {
  position: sticky;
  position: -webkit-sticky;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  animation: ease 200s alternate infinite;
  pointer-events: none;
}

.firefly::before,
.firefly::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform-origin: -10vw;
}

.firefly::before {
  background: black;
  opacity: 0.4;
  animation: drift ease alternate infinite;
}

.firefly::after {
  background: white;
  opacity: 0;
  box-shadow: 0 0 0vw 0vw #31ff40;
  animation: drift ease alternate infinite, flash ease infinite;
}

.firefly:nth-child(1) {
  animation-name: move1;
}

.firefly:nth-child(1)::before {
  animation-duration: 10s;
}

.firefly:nth-child(1)::after {
  animation-duration: 10s, 5869ms;
  animation-delay: 0ms, 5103ms;
}

@keyframes move1 {
  0% {
    transform: translateX(-41vw) translateY(23vh) scale(0.42);
  }

  5% {
    transform: translateX(9vw) translateY(-45vh) scale(0.34);
  }

  10% {
    transform: translateX(-17vw) translateY(-42vh) scale(1);
  }

  15% {
    transform: translateX(8vw) translateY(-23vh) scale(0.51);
  }

  20% {
    transform: translateX(-29vw) translateY(-20vh) scale(0.56);
  }

  25% {
    transform: translateX(8vw) translateY(16vh) scale(0.79);
  }

  30% {
    transform: translateX(11vw) translateY(46vh) scale(0.54);
  }

  35% {
    transform: translateX(9vw) translateY(-45vh) scale(0.58);
  }

  40% {
    transform: translateX(10vw) translateY(-37vh) scale(0.69);
  }

  45% {
    transform: translateX(5vw) translateY(26vh) scale(0.52);
  }

  50% {
    transform: translateX(-36vw) translateY(-39vh) scale(0.43);
  }

  55% {
    transform: translateX(-45vw) translateY(35vh) scale(0.96);
  }

  60% {
    transform: translateX(17vw) translateY(38vh) scale(0.66);
  }

  65% {
    transform: translateX(-39vw) translateY(-34vh) scale(0.75);
  }

  70% {
    transform: translateX(-45vw) translateY(18vh) scale(0.72);
  }

  75% {
    transform: translateX(48vw) translateY(21vh) scale(0.42);
  }

  80% {
    transform: translateX(28vw) translateY(-2vh) scale(0.82);
  }

  85% {
    transform: translateX(-29vw) translateY(30vh) scale(0.48);
  }

  90% {
    transform: translateX(12vw) translateY(16vh) scale(0.42);
  }

  95% {
    transform: translateX(-8vw) translateY(-24vh) scale(0.52);
  }

  100% {
    transform: translateX(-49vw) translateY(6vh) scale(0.34);
  }
}

.firefly:nth-child(2) {
  animation-name: move2;
}

.firefly:nth-child(2)::before {
  animation-duration: 13s;
}

.firefly:nth-child(2)::after {
  animation-duration: 13s, 8622ms;
  animation-delay: 0ms, 6050ms;
}

@keyframes move2 {
  0% {
    transform: translateX(-13vw) translateY(17vh) scale(0.94);
  }

  4.347826087% {
    transform: translateX(12vw) translateY(24vh) scale(0.48);
  }

  8.6956521739% {
    transform: translateX(16vw) translateY(-40vh) scale(0.39);
  }

  13.0434782609% {
    transform: translateX(22vw) translateY(22vh) scale(0.68);
  }

  17.3913043478% {
    transform: translateX(19vw) translateY(-14vh) scale(0.42);
  }

  21.7391304348% {
    transform: translateX(2vw) translateY(-18vh) scale(0.3);
  }

  26.0869565217% {
    transform: translateX(2vw) translateY(38vh) scale(0.29);
  }

  30.4347826087% {
    transform: translateX(28vw) translateY(6vh) scale(0.87);
  }

  34.7826086957% {
    transform: translateX(-48vw) translateY(21vh) scale(0.88);
  }

  39.1304347826% {
    transform: translateX(32vw) translateY(15vh) scale(0.76);
  }

  43.4782608696% {
    transform: translateX(32vw) translateY(1vh) scale(0.31);
  }

  47.8260869565% {
    transform: translateX(35vw) translateY(0vh) scale(0.29);
  }

  52.1739130435% {
    transform: translateX(9vw) translateY(14vh) scale(0.27);
  }

  56.5217391304% {
    transform: translateX(12vw) translateY(-20vh) scale(0.64);
  }

  60.8695652174% {
    transform: translateX(-21vw) translateY(27vh) scale(0.72);
  }

  65.2173913043% {
    transform: translateX(-47vw) translateY(-33vh) scale(0.31);
  }

  69.5652173913% {
    transform: translateX(-25vw) translateY(7vh) scale(0.85);
  }

  73.9130434783% {
    transform: translateX(42vw) translateY(38vh) scale(0.88);
  }

  78.2608695652% {
    transform: translateX(11vw) translateY(10vh) scale(0.91);
  }

  82.6086956522% {
    transform: translateX(2vw) translateY(6vh) scale(0.59);
  }

  86.9565217391% {
    transform: translateX(37vw) translateY(4vh) scale(0.59);
  }

  91.3043478261% {
    transform: translateX(-34vw) translateY(-32vh) scale(0.95);
  }

  95.652173913% {
    transform: translateX(-47vw) translateY(32vh) scale(0.81);
  }

  100% {
    transform: translateX(32vw) translateY(22vh) scale(0.53);
  }
}

.firefly:nth-child(3) {
  animation-name: move3;
}

.firefly:nth-child(3)::before {
  animation-duration: 14s;
}

.firefly:nth-child(3)::after {
  animation-duration: 14s, 7972ms;
  animation-delay: 0ms, 2219ms;
}

@keyframes move3 {
  0% {
    transform: translateX(-9vw) translateY(-12vh) scale(0.35);
  }

  4% {
    transform: translateX(9vw) translateY(45vh) scale(0.75);
  }

  8% {
    transform: translateX(37vw) translateY(-35vh) scale(0.42);
  }

  12% {
    transform: translateX(-49vw) translateY(5vh) scale(0.46);
  }

  16% {
    transform: translateX(42vw) translateY(26vh) scale(0.34);
  }

  20% {
    transform: translateX(-12vw) translateY(19vh) scale(0.84);
  }

  24% {
    transform: translateX(23vw) translateY(6vh) scale(0.45);
  }

  28% {
    transform: translateX(18vw) translateY(-16vh) scale(0.47);
  }

  32% {
    transform: translateX(22vw) translateY(-40vh) scale(0.45);
  }

  36% {
    transform: translateX(-37vw) translateY(33vh) scale(0.75);
  }

  40% {
    transform: translateX(44vw) translateY(33vh) scale(0.61);
  }

  44% {
    transform: translateX(-44vw) translateY(-18vh) scale(0.57);
  }

  48% {
    transform: translateX(-9vw) translateY(39vh) scale(0.45);
  }

  52% {
    transform: translateX(10vw) translateY(41vh) scale(0.51);
  }

  56% {
    transform: translateX(23vw) translateY(-41vh) scale(0.31);
  }

  60% {
    transform: translateX(48vw) translateY(-5vh) scale(0.33);
  }

  64% {
    transform: translateX(-49vw) translateY(-43vh) scale(0.3);
  }

  68% {
    transform: translateX(-39vw) translateY(5vh) scale(0.44);
  }

  72% {
    transform: translateX(-18vw) translateY(-28vh) scale(0.94);
  }

  76% {
    transform: translateX(22vw) translateY(-18vh) scale(0.67);
  }

  80% {
    transform: translateX(10vw) translateY(-32vh) scale(0.56);
  }

  84% {
    transform: translateX(-7vw) translateY(-48vh) scale(0.47);
  }

  88% {
    transform: translateX(30vw) translateY(37vh) scale(0.64);
  }

  92% {
    transform: translateX(-46vw) translateY(-37vh) scale(0.53);
  }

  96% {
    transform: translateX(9vw) translateY(40vh) scale(0.87);
  }

  100% {
    transform: translateX(-48vw) translateY(-17vh) scale(0.46);
  }
}

.firefly:nth-child(4) {
  animation-name: move4;
}

.firefly:nth-child(4)::before {
  animation-duration: 10s;
}

.firefly:nth-child(4)::after {
  animation-duration: 10s, 8866ms;
  animation-delay: 0ms, 4122ms;
}

@keyframes move4 {
  0% {
    transform: translateX(-1vw) translateY(17vh) scale(0.76);
  }

  5.5555555556% {
    transform: translateX(-20vw) translateY(-6vh) scale(0.96);
  }

  11.1111111111% {
    transform: translateX(7vw) translateY(27vh) scale(0.44);
  }

  16.6666666667% {
    transform: translateX(1vw) translateY(32vh) scale(0.75);
  }

  22.2222222222% {
    transform: translateX(48vw) translateY(-14vh) scale(0.83);
  }

  27.7777777778% {
    transform: translateX(-34vw) translateY(-30vh) scale(0.6);
  }

  33.3333333333% {
    transform: translateX(40vw) translateY(-27vh) scale(1);
  }

  38.8888888889% {
    transform: translateX(-14vw) translateY(-28vh) scale(0.29);
  }

  44.4444444444% {
    transform: translateX(37vw) translateY(-14vh) scale(0.56);
  }

  50% {
    transform: translateX(29vw) translateY(8vh) scale(0.37);
  }

  55.5555555556% {
    transform: translateX(-14vw) translateY(21vh) scale(0.39);
  }

  61.1111111111% {
    transform: translateX(-1vw) translateY(38vh) scale(0.64);
  }

  66.6666666667% {
    transform: translateX(40vw) translateY(-17vh) scale(0.78);
  }

  72.2222222222% {
    transform: translateX(-6vw) translateY(-45vh) scale(0.54);
  }

  77.7777777778% {
    transform: translateX(-47vw) translateY(-46vh) scale(0.38);
  }

  83.3333333333% {
    transform: translateX(38vw) translateY(-13vh) scale(0.86);
  }

  88.8888888889% {
    transform: translateX(-8vw) translateY(35vh) scale(0.39);
  }

  94.4444444444% {
    transform: translateX(-25vw) translateY(-1vh) scale(0.44);
  }

  100% {
    transform: translateX(0vw) translateY(-34vh) scale(0.57);
  }
}

.firefly:nth-child(5) {
  animation-name: move5;
}

.firefly:nth-child(5)::before {
  animation-duration: 18s;
}

.firefly:nth-child(5)::after {
  animation-duration: 18s, 6640ms;
  animation-delay: 0ms, 1937ms;
}

@keyframes move5 {
  0% {
    transform: translateX(33vw) translateY(-14vh) scale(0.83);
  }

  4.5454545455% {
    transform: translateX(-14vw) translateY(6vh) scale(0.26);
  }

  9.0909090909% {
    transform: translateX(-15vw) translateY(-12vh) scale(0.56);
  }

  13.6363636364% {
    transform: translateX(39vw) translateY(20vh) scale(0.69);
  }

  18.1818181818% {
    transform: translateX(-21vw) translateY(-1vh) scale(0.27);
  }

  22.7272727273% {
    transform: translateX(-38vw) translateY(47vh) scale(0.43);
  }

  27.2727272727% {
    transform: translateX(10vw) translateY(-4vh) scale(0.53);
  }

  31.8181818182% {
    transform: translateX(-13vw) translateY(21vh) scale(0.88);
  }

  36.3636363636% {
    transform: translateX(-3vw) translateY(46vh) scale(0.76);
  }

  40.9090909091% {
    transform: translateX(-2vw) translateY(-11vh) scale(0.32);
  }

  45.4545454545% {
    transform: translateX(14vw) translateY(4vh) scale(0.32);
  }

  50% {
    transform: translateX(8vw) translateY(8vh) scale(0.76);
  }

  54.5454545455% {
    transform: translateX(18vw) translateY(-35vh) scale(0.6);
  }

  59.0909090909% {
    transform: translateX(15vw) translateY(-43vh) scale(0.28);
  }

  63.6363636364% {
    transform: translateX(-12vw) translateY(12vh) scale(0.68);
  }

  68.1818181818% {
    transform: translateX(5vw) translateY(25vh) scale(0.96);
  }

  72.7272727273% {
    transform: translateX(-21vw) translateY(-35vh) scale(0.4);
  }

  77.2727272727% {
    transform: translateX(-44vw) translateY(7vh) scale(0.98);
  }

  81.8181818182% {
    transform: translateX(40vw) translateY(-29vh) scale(0.91);
  }

  86.3636363636% {
    transform: translateX(30vw) translateY(-36vh) scale(0.49);
  }

  90.9090909091% {
    transform: translateX(48vw) translateY(22vh) scale(0.67);
  }

  95.4545454545% {
    transform: translateX(18vw) translateY(-10vh) scale(0.77);
  }

  100% {
    transform: translateX(-40vw) translateY(-6vh) scale(0.91);
  }
}

.firefly:nth-child(6) {
  animation-name: move6;
}

.firefly:nth-child(6)::before {
  animation-duration: 15s;
}

.firefly:nth-child(6)::after {
  animation-duration: 15s, 8079ms;
  animation-delay: 0ms, 6703ms;
}

@keyframes move6 {
  0% {
    transform: translateX(7vw) translateY(-40vh) scale(0.74);
  }

  4.7619047619% {
    transform: translateX(-23vw) translateY(32vh) scale(0.7);
  }

  9.5238095238% {
    transform: translateX(-9vw) translateY(0vh) scale(0.34);
  }

  14.2857142857% {
    transform: translateX(-36vw) translateY(18vh) scale(0.65);
  }

  19.0476190476% {
    transform: translateX(37vw) translateY(-8vh) scale(0.84);
  }

  23.8095238095% {
    transform: translateX(7vw) translateY(27vh) scale(0.44);
  }

  28.5714285714% {
    transform: translateX(-3vw) translateY(8vh) scale(0.93);
  }

  33.3333333333% {
    transform: translateX(-18vw) translateY(5vh) scale(0.87);
  }

  38.0952380952% {
    transform: translateX(25vw) translateY(19vh) scale(0.45);
  }

  42.8571428571% {
    transform: translateX(46vw) translateY(14vh) scale(0.95);
  }

  47.619047619% {
    transform: translateX(13vw) translateY(-10vh) scale(0.5);
  }

  52.380952381% {
    transform: translateX(15vw) translateY(11vh) scale(0.4);
  }

  57.1428571429% {
    transform: translateX(15vw) translateY(-35vh) scale(0.96);
  }

  61.9047619048% {
    transform: translateX(-13vw) translateY(41vh) scale(0.35);
  }

  66.6666666667% {
    transform: translateX(-23vw) translateY(26vh) scale(0.9);
  }

  71.4285714286% {
    transform: translateX(-7vw) translateY(4vh) scale(0.81);
  }

  76.1904761905% {
    transform: translateX(-49vw) translateY(-18vh) scale(0.84);
  }

  80.9523809524% {
    transform: translateX(26vw) translateY(33vh) scale(0.43);
  }

  85.7142857143% {
    transform: translateX(-6vw) translateY(23vh) scale(0.48);
  }

  90.4761904762% {
    transform: translateX(46vw) translateY(-5vh) scale(0.8);
  }

  95.2380952381% {
    transform: translateX(42vw) translateY(33vh) scale(0.6);
  }

  100% {
    transform: translateX(-28vw) translateY(26vh) scale(0.64);
  }
}

.firefly:nth-child(7) {
  animation-name: move7;
}

.firefly:nth-child(7)::before {
  animation-duration: 17s;
}

.firefly:nth-child(7)::after {
  animation-duration: 17s, 9827ms;
  animation-delay: 0ms, 3278ms;
}

@keyframes move7 {
  0% {
    transform: translateX(-1vw) translateY(41vh) scale(0.51);
  }

  5.5555555556% {
    transform: translateX(38vw) translateY(32vh) scale(0.61);
  }

  11.1111111111% {
    transform: translateX(36vw) translateY(44vh) scale(0.89);
  }

  16.6666666667% {
    transform: translateX(-22vw) translateY(-36vh) scale(0.68);
  }

  22.2222222222% {
    transform: translateX(4vw) translateY(-2vh) scale(0.87);
  }

  27.7777777778% {
    transform: translateX(11vw) translateY(-5vh) scale(0.48);
  }

  33.3333333333% {
    transform: translateX(-30vw) translateY(28vh) scale(0.43);
  }

  38.8888888889% {
    transform: translateX(-38vw) translateY(-4vh) scale(0.84);
  }

  44.4444444444% {
    transform: translateX(-29vw) translateY(33vh) scale(0.43);
  }

  50% {
    transform: translateX(1vw) translateY(-4vh) scale(0.96);
  }

  55.5555555556% {
    transform: translateX(38vw) translateY(-23vh) scale(0.5);
  }

  61.1111111111% {
    transform: translateX(-47vw) translateY(-1vh) scale(0.91);
  }

  66.6666666667% {
    transform: translateX(40vw) translateY(-8vh) scale(0.52);
  }

  72.2222222222% {
    transform: translateX(26vw) translateY(14vh) scale(0.83);
  }

  77.7777777778% {
    transform: translateX(17vw) translateY(-48vh) scale(0.97);
  }

  83.3333333333% {
    transform: translateX(-22vw) translateY(45vh) scale(0.85);
  }

  88.8888888889% {
    transform: translateX(-21vw) translateY(-9vh) scale(0.45);
  }

  94.4444444444% {
    transform: translateX(39vw) translateY(38vh) scale(0.59);
  }

  100% {
    transform: translateX(-37vw) translateY(-2vh) scale(0.55);
  }
}

.firefly:nth-child(8) {
  animation-name: move8;
}

.firefly:nth-child(8)::before {
  animation-duration: 9s;
}

.firefly:nth-child(8)::after {
  animation-duration: 9s, 8380ms;
  animation-delay: 0ms, 4603ms;
}

@keyframes move8 {
  0% {
    transform: translateX(-17vw) translateY(30vh) scale(0.58);
  }

  5.8823529412% {
    transform: translateX(-13vw) translateY(-42vh) scale(0.82);
  }

  11.7647058824% {
    transform: translateX(-10vw) translateY(-38vh) scale(0.86);
  }

  17.6470588235% {
    transform: translateX(49vw) translateY(47vh) scale(0.97);
  }

  23.5294117647% {
    transform: translateX(-17vw) translateY(-44vh) scale(1);
  }

  29.4117647059% {
    transform: translateX(-6vw) translateY(-20vh) scale(0.5);
  }

  35.2941176471% {
    transform: translateX(-39vw) translateY(-34vh) scale(0.33);
  }

  41.1764705882% {
    transform: translateX(38vw) translateY(49vh) scale(0.45);
  }

  47.0588235294% {
    transform: translateX(43vw) translateY(39vh) scale(0.43);
  }

  52.9411764706% {
    transform: translateX(46vw) translateY(-4vh) scale(0.27);
  }

  58.8235294118% {
    transform: translateX(-16vw) translateY(-20vh) scale(0.71);
  }

  64.7058823529% {
    transform: translateX(-43vw) translateY(-40vh) scale(0.84);
  }

  70.5882352941% {
    transform: translateX(-10vw) translateY(13vh) scale(0.81);
  }

  76.4705882353% {
    transform: translateX(38vw) translateY(1vh) scale(0.36);
  }

  82.3529411765% {
    transform: translateX(32vw) translateY(-40vh) scale(0.71);
  }

  88.2352941176% {
    transform: translateX(0vw) translateY(-49vh) scale(0.32);
  }

  94.1176470588% {
    transform: translateX(12vw) translateY(-49vh) scale(0.29);
  }

  100% {
    transform: translateX(7vw) translateY(-3vh) scale(0.94);
  }
}

.firefly:nth-child(9) {
  animation-name: move9;
}

.firefly:nth-child(9)::before {
  animation-duration: 18s;
}

.firefly:nth-child(9)::after {
  animation-duration: 18s, 10823ms;
  animation-delay: 0ms, 705ms;
}

@keyframes move9 {
  0% {
    transform: translateX(3vw) translateY(4vh) scale(0.41);
  }

  5.2631578947% {
    transform: translateX(-43vw) translateY(40vh) scale(0.79);
  }

  10.5263157895% {
    transform: translateX(-39vw) translateY(22vh) scale(0.38);
  }

  15.7894736842% {
    transform: translateX(-32vw) translateY(1vh) scale(0.41);
  }

  21.0526315789% {
    transform: translateX(-33vw) translateY(31vh) scale(0.81);
  }

  26.3157894737% {
    transform: translateX(2vw) translateY(-33vh) scale(0.43);
  }

  31.5789473684% {
    transform: translateX(-2vw) translateY(41vh) scale(0.78);
  }

  36.8421052632% {
    transform: translateX(-1vw) translateY(25vh) scale(0.69);
  }

  42.1052631579% {
    transform: translateX(-39vw) translateY(-28vh) scale(0.94);
  }

  47.3684210526% {
    transform: translateX(29vw) translateY(13vh) scale(0.42);
  }

  52.6315789474% {
    transform: translateX(42vw) translateY(17vh) scale(0.47);
  }

  57.8947368421% {
    transform: translateX(14vw) translateY(-9vh) scale(0.59);
  }

  63.1578947368% {
    transform: translateX(12vw) translateY(-8vh) scale(0.29);
  }

  68.4210526316% {
    transform: translateX(-31vw) translateY(-25vh) scale(0.81);
  }

  73.6842105263% {
    transform: translateX(-45vw) translateY(13vh) scale(0.33);
  }

  78.9473684211% {
    transform: translateX(-6vw) translateY(-48vh) scale(0.86);
  }

  84.2105263158% {
    transform: translateX(-18vw) translateY(-12vh) scale(0.99);
  }

  89.4736842105% {
    transform: translateX(22vw) translateY(-39vh) scale(0.51);
  }

  94.7368421053% {
    transform: translateX(43vw) translateY(-15vh) scale(0.6);
  }

  100% {
    transform: translateX(41vw) translateY(-14vh) scale(0.56);
  }
}

.firefly:nth-child(10) {
  animation-name: move10;
}

.firefly:nth-child(10)::before {
  animation-duration: 15s;
}

.firefly:nth-child(10)::after {
  animation-duration: 15s, 9714ms;
  animation-delay: 0ms, 7928ms;
}

@keyframes move10 {
  0% {
    transform: translateX(-5vw) translateY(25vh) scale(0.3);
  }

  3.8461538462% {
    transform: translateX(11vw) translateY(42vh) scale(0.81);
  }

  7.6923076923% {
    transform: translateX(-45vw) translateY(25vh) scale(0.44);
  }

  11.5384615385% {
    transform: translateX(23vw) translateY(-4vh) scale(0.43);
  }

  15.3846153846% {
    transform: translateX(10vw) translateY(12vh) scale(0.84);
  }

  19.2307692308% {
    transform: translateX(-33vw) translateY(38vh) scale(0.95);
  }

  23.0769230769% {
    transform: translateX(15vw) translateY(23vh) scale(0.56);
  }

  26.9230769231% {
    transform: translateX(-12vw) translateY(-17vh) scale(0.42);
  }

  30.7692307692% {
    transform: translateX(1vw) translateY(-34vh) scale(0.61);
  }

  34.6153846154% {
    transform: translateX(22vw) translateY(-25vh) scale(0.32);
  }

  38.4615384615% {
    transform: translateX(-17vw) translateY(26vh) scale(0.65);
  }

  42.3076923077% {
    transform: translateX(3vw) translateY(29vh) scale(0.91);
  }

  46.1538461538% {
    transform: translateX(-12vw) translateY(-36vh) scale(0.64);
  }

  50% {
    transform: translateX(-44vw) translateY(41vh) scale(0.44);
  }

  53.8461538462% {
    transform: translateX(9vw) translateY(-29vh) scale(0.6);
  }

  57.6923076923% {
    transform: translateX(22vw) translateY(30vh) scale(0.98);
  }

  61.5384615385% {
    transform: translateX(-20vw) translateY(33vh) scale(0.7);
  }

  65.3846153846% {
    transform: translateX(40vw) translateY(24vh) scale(0.38);
  }

  69.2307692308% {
    transform: translateX(-19vw) translateY(4vh) scale(0.48);
  }

  73.0769230769% {
    transform: translateX(-18vw) translateY(-32vh) scale(1);
  }

  76.9230769231% {
    transform: translateX(17vw) translateY(1vh) scale(0.68);
  }

  80.7692307692% {
    transform: translateX(-40vw) translateY(-16vh) scale(0.92);
  }

  84.6153846154% {
    transform: translateX(17vw) translateY(32vh) scale(0.9);
  }

  88.4615384615% {
    transform: translateX(14vw) translateY(45vh) scale(0.8);
  }

  92.3076923077% {
    transform: translateX(14vw) translateY(50vh) scale(0.74);
  }

  96.1538461538% {
    transform: translateX(26vw) translateY(47vh) scale(0.83);
  }

  100% {
    transform: translateX(49vw) translateY(-40vh) scale(0.95);
  }
}

.firefly:nth-child(11) {
  animation-name: move11;
}

.firefly:nth-child(11)::before {
  animation-duration: 13s;
}

.firefly:nth-child(11)::after {
  animation-duration: 13s, 7476ms;
  animation-delay: 0ms, 5436ms;
}

@keyframes move11 {
  0% {
    transform: translateX(-7vw) translateY(16vh) scale(0.42);
  }

  4.1666666667% {
    transform: translateX(-44vw) translateY(14vh) scale(0.89);
  }

  8.3333333333% {
    transform: translateX(17vw) translateY(-25vh) scale(0.72);
  }

  12.5% {
    transform: translateX(-40vw) translateY(-22vh) scale(0.47);
  }

  16.6666666667% {
    transform: translateX(49vw) translateY(12vh) scale(0.93);
  }

  20.8333333333% {
    transform: translateX(-45vw) translateY(21vh) scale(0.6);
  }

  25% {
    transform: translateX(36vw) translateY(-41vh) scale(0.48);
  }

  29.1666666667% {
    transform: translateX(-9vw) translateY(0vh) scale(0.98);
  }

  33.3333333333% {
    transform: translateX(-32vw) translateY(37vh) scale(0.29);
  }

  37.5% {
    transform: translateX(31vw) translateY(-46vh) scale(0.34);
  }

  41.6666666667% {
    transform: translateX(21vw) translateY(-45vh) scale(0.51);
  }

  45.8333333333% {
    transform: translateX(-12vw) translateY(-46vh) scale(0.87);
  }

  50% {
    transform: translateX(25vw) translateY(28vh) scale(0.48);
  }

  54.1666666667% {
    transform: translateX(-16vw) translateY(-25vh) scale(0.76);
  }

  58.3333333333% {
    transform: translateX(-4vw) translateY(48vh) scale(0.63);
  }

  62.5% {
    transform: translateX(48vw) translateY(-32vh) scale(0.31);
  }

  66.6666666667% {
    transform: translateX(-39vw) translateY(-39vh) scale(0.56);
  }

  70.8333333333% {
    transform: translateX(-1vw) translateY(37vh) scale(0.5);
  }

  75% {
    transform: translateX(-9vw) translateY(-5vh) scale(0.56);
  }

  79.1666666667% {
    transform: translateX(-11vw) translateY(13vh) scale(0.96);
  }

  83.3333333333% {
    transform: translateX(-1vw) translateY(-41vh) scale(0.55);
  }

  87.5% {
    transform: translateX(17vw) translateY(18vh) scale(0.58);
  }

  91.6666666667% {
    transform: translateX(-12vw) translateY(10vh) scale(0.99);
  }

  95.8333333333% {
    transform: translateX(33vw) translateY(50vh) scale(0.85);
  }

  100% {
    transform: translateX(43vw) translateY(38vh) scale(0.52);
  }
}

.firefly:nth-child(12) {
  animation-name: move12;
}

.firefly:nth-child(12)::before {
  animation-duration: 13s;
}

.firefly:nth-child(12)::after {
  animation-duration: 13s, 5428ms;
  animation-delay: 0ms, 4447ms;
}

@keyframes move12 {
  0% {
    transform: translateX(19vw) translateY(-25vh) scale(0.71);
  }

  3.7037037037% {
    transform: translateX(5vw) translateY(-44vh) scale(0.68);
  }

  7.4074074074% {
    transform: translateX(-11vw) translateY(21vh) scale(0.96);
  }

  11.1111111111% {
    transform: translateX(-16vw) translateY(-35vh) scale(0.62);
  }

  14.8148148148% {
    transform: translateX(19vw) translateY(36vh) scale(0.51);
  }

  18.5185185185% {
    transform: translateX(-31vw) translateY(39vh) scale(0.91);
  }

  22.2222222222% {
    transform: translateX(-13vw) translateY(-33vh) scale(0.57);
  }

  25.9259259259% {
    transform: translateX(-11vw) translateY(-43vh) scale(0.91);
  }

  29.6296296296% {
    transform: translateX(6vw) translateY(-23vh) scale(0.35);
  }

  33.3333333333% {
    transform: translateX(50vw) translateY(-5vh) scale(1);
  }

  37.037037037% {
    transform: translateX(-42vw) translateY(0vh) scale(0.98);
  }

  40.7407407407% {
    transform: translateX(35vw) translateY(40vh) scale(0.97);
  }

  44.4444444444% {
    transform: translateX(-6vw) translateY(-30vh) scale(0.28);
  }

  48.1481481481% {
    transform: translateX(7vw) translateY(0vh) scale(0.85);
  }

  51.8518518519% {
    transform: translateX(-29vw) translateY(-42vh) scale(0.82);
  }

  55.5555555556% {
    transform: translateX(-13vw) translateY(-39vh) scale(0.93);
  }

  59.2592592593% {
    transform: translateX(-16vw) translateY(43vh) scale(0.99);
  }

  62.962962963% {
    transform: translateX(19vw) translateY(38vh) scale(0.61);
  }

  66.6666666667% {
    transform: translateX(13vw) translateY(26vh) scale(0.75);
  }

  70.3703703704% {
    transform: translateX(-3vw) translateY(-23vh) scale(0.7);
  }

  74.0740740741% {
    transform: translateX(-19vw) translateY(3vh) scale(0.93);
  }

  77.7777777778% {
    transform: translateX(8vw) translateY(-18vh) scale(0.77);
  }

  81.4814814815% {
    transform: translateX(-23vw) translateY(-12vh) scale(0.88);
  }

  85.1851851852% {
    transform: translateX(29vw) translateY(-27vh) scale(0.75);
  }

  88.8888888889% {
    transform: translateX(-28vw) translateY(-32vh) scale(0.46);
  }

  92.5925925926% {
    transform: translateX(-40vw) translateY(11vh) scale(0.54);
  }

  96.2962962963% {
    transform: translateX(19vw) translateY(3vh) scale(0.86);
  }

  100% {
    transform: translateX(35vw) translateY(-45vh) scale(0.73);
  }
}

.firefly:nth-child(13) {
  animation-name: move13;
}

.firefly:nth-child(13)::before {
  animation-duration: 12s;
}

.firefly:nth-child(13)::after {
  animation-duration: 12s, 10857ms;
  animation-delay: 0ms, 8399ms;
}

@keyframes move13 {
  0% {
    transform: translateX(-20vw) translateY(28vh) scale(0.31);
  }

  5.2631578947% {
    transform: translateX(10vw) translateY(-35vh) scale(0.91);
  }

  10.5263157895% {
    transform: translateX(-12vw) translateY(7vh) scale(0.65);
  }

  15.7894736842% {
    transform: translateX(-35vw) translateY(-3vh) scale(0.56);
  }

  21.0526315789% {
    transform: translateX(-24vw) translateY(-16vh) scale(0.35);
  }

  26.3157894737% {
    transform: translateX(0vw) translateY(20vh) scale(0.57);
  }

  31.5789473684% {
    transform: translateX(-45vw) translateY(-3vh) scale(0.77);
  }

  36.8421052632% {
    transform: translateX(37vw) translateY(36vh) scale(0.7);
  }

  42.1052631579% {
    transform: translateX(-9vw) translateY(8vh) scale(0.92);
  }

  47.3684210526% {
    transform: translateX(-14vw) translateY(-41vh) scale(0.29);
  }

  52.6315789474% {
    transform: translateX(-12vw) translateY(-35vh) scale(0.71);
  }

  57.8947368421% {
    transform: translateX(-3vw) translateY(-21vh) scale(0.48);
  }

  63.1578947368% {
    transform: translateX(12vw) translateY(6vh) scale(0.8);
  }

  68.4210526316% {
    transform: translateX(16vw) translateY(-5vh) scale(0.75);
  }

  73.6842105263% {
    transform: translateX(-39vw) translateY(-43vh) scale(0.68);
  }

  78.9473684211% {
    transform: translateX(35vw) translateY(-46vh) scale(0.3);
  }

  84.2105263158% {
    transform: translateX(35vw) translateY(-45vh) scale(0.83);
  }

  89.4736842105% {
    transform: translateX(-8vw) translateY(13vh) scale(0.39);
  }

  94.7368421053% {
    transform: translateX(-12vw) translateY(32vh) scale(0.45);
  }

  100% {
    transform: translateX(-14vw) translateY(42vh) scale(0.67);
  }
}

.firefly:nth-child(14) {
  animation-name: move14;
}

.firefly:nth-child(14)::before {
  animation-duration: 11s;
}

.firefly:nth-child(14)::after {
  animation-duration: 11s, 7829ms;
  animation-delay: 0ms, 5814ms;
}

@keyframes move14 {
  0% {
    transform: translateX(10vw) translateY(-18vh) scale(0.99);
  }

  5.8823529412% {
    transform: translateX(7vw) translateY(18vh) scale(0.53);
  }

  11.7647058824% {
    transform: translateX(-38vw) translateY(3vh) scale(0.96);
  }

  17.6470588235% {
    transform: translateX(22vw) translateY(-38vh) scale(0.49);
  }

  23.5294117647% {
    transform: translateX(8vw) translateY(-38vh) scale(0.41);
  }

  29.4117647059% {
    transform: translateX(34vw) translateY(26vh) scale(0.7);
  }

  35.2941176471% {
    transform: translateX(-7vw) translateY(-3vh) scale(0.52);
  }

  41.1764705882% {
    transform: translateX(-43vw) translateY(45vh) scale(0.43);
  }

  47.0588235294% {
    transform: translateX(47vw) translateY(9vh) scale(0.89);
  }

  52.9411764706% {
    transform: translateX(45vw) translateY(32vh) scale(0.77);
  }

  58.8235294118% {
    transform: translateX(8vw) translateY(16vh) scale(0.64);
  }

  64.7058823529% {
    transform: translateX(-45vw) translateY(-38vh) scale(0.92);
  }

  70.5882352941% {
    transform: translateX(-6vw) translateY(-40vh) scale(0.64);
  }

  76.4705882353% {
    transform: translateX(29vw) translateY(-27vh) scale(0.83);
  }

  82.3529411765% {
    transform: translateX(4vw) translateY(10vh) scale(0.86);
  }

  88.2352941176% {
    transform: translateX(-8vw) translateY(43vh) scale(0.91);
  }

  94.1176470588% {
    transform: translateX(33vw) translateY(10vh) scale(0.57);
  }

  100% {
    transform: translateX(-17vw) translateY(14vh) scale(0.83);
  }
}

.firefly:nth-child(15) {
  animation-name: move15;
}

.firefly:nth-child(15)::before {
  animation-duration: 14s;
}

.firefly:nth-child(15)::after {
  animation-duration: 14s, 8455ms;
  animation-delay: 0ms, 1227ms;
}

@keyframes move15 {
  0% {
    transform: translateX(12vw) translateY(-1vh) scale(0.28);
  }

  4% {
    transform: translateX(6vw) translateY(-14vh) scale(0.61);
  }

  8% {
    transform: translateX(9vw) translateY(-13vh) scale(0.51);
  }

  12% {
    transform: translateX(26vw) translateY(14vh) scale(0.81);
  }

  16% {
    transform: translateX(-32vw) translateY(7vh) scale(0.99);
  }

  20% {
    transform: translateX(19vw) translateY(10vh) scale(0.27);
  }

  24% {
    transform: translateX(-13vw) translateY(-10vh) scale(0.83);
  }

  28% {
    transform: translateX(-35vw) translateY(-2vh) scale(0.39);
  }

  32% {
    transform: translateX(-28vw) translateY(-13vh) scale(0.82);
  }

  36% {
    transform: translateX(-45vw) translateY(28vh) scale(0.48);
  }

  40% {
    transform: translateX(-5vw) translateY(42vh) scale(0.74);
  }

  44% {
    transform: translateX(-30vw) translateY(22vh) scale(0.44);
  }

  48% {
    transform: translateX(15vw) translateY(-21vh) scale(0.55);
  }

  52% {
    transform: translateX(-6vw) translateY(1vh) scale(0.94);
  }

  56% {
    transform: translateX(-26vw) translateY(13vh) scale(0.26);
  }

  60% {
    transform: translateX(44vw) translateY(-49vh) scale(0.67);
  }

  64% {
    transform: translateX(23vw) translateY(-28vh) scale(0.35);
  }

  68% {
    transform: translateX(35vw) translateY(37vh) scale(0.56);
  }

  72% {
    transform: translateX(-4vw) translateY(43vh) scale(0.76);
  }

  76% {
    transform: translateX(-43vw) translateY(43vh) scale(0.56);
  }

  80% {
    transform: translateX(-12vw) translateY(1vh) scale(0.99);
  }

  84% {
    transform: translateX(-26vw) translateY(3vh) scale(0.3);
  }

  88% {
    transform: translateX(46vw) translateY(-9vh) scale(0.55);
  }

  92% {
    transform: translateX(33vw) translateY(-15vh) scale(0.66);
  }

  96% {
    transform: translateX(19vw) translateY(-3vh) scale(0.59);
  }

  100% {
    transform: translateX(-32vw) translateY(-18vh) scale(0.31);
  }
}

@keyframes drift {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes flash {

  0%,
  30%,
  100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #31ff40;
  }

  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw #31ff40;
  }
}